import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate , Link} from 'react-router-dom';
import { Container, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import './Landing.css';
import { ToastContainer, toast } from 'react-toastify';
import ForgotPassword from './OTPLogin/ForgotPassword';
import OtpLogin from './OTPLogin/OtpRequest'; // Import the OTP login component
import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/sessionsetup.scss';
import { Select } from '@react-three/drei';
import PrivacyPolicy from './PrivacyPolicy';
import { API_BASE_URL } from 'Config';
export default function Landing() {

    const navigate = useNavigate();

    const [registerFormErrors, setRegisterFormErrors] = useState({});
    const [loginErrorData, setLoginErrorData] = useState({});
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false); 
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [termsAndCondtionalModal, setTermsAndCondtionalModal] = useState(false);
    const [activeContinueButton, setActiveContinueButton] = useState(true);
    const [otpLoginModal, setOtpLoginModal] = useState(false); // New state for OTP login card
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [user_type, setUserType] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newsletterChecked, setNewsletterChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [ageChecked, setAgeChecked] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const notify = () => toast("Your Successfully Login");
    const notifyRegister = () => toast('Your Registred Successfully');
    useEffect(() => {
        // Check if user is logged in (e.g., check localStorage for a token)
        const token = localStorage.getItem('token');
        setIsUserLoggedIn(!!token);
        if (!token) {
            
        } else {
            navigate('/session');
        }
    }, []);

    const handleNewsletterChange = () => {
        setNewsletterChecked(!newsletterChecked);
    };

    const handleTermsChange = () => {
        setTermsChecked(!termsChecked);
    };

    const handleAgeChange = () => {
        setAgeChecked(!ageChecked);
    };

    const handleLogin = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Login successful', data);
                notify();
                // Save token to localStorage or context/state
                const expirationTime = new Date().getTime() + 6 * 60 * 60 * 1000; // Current time + 2 hours in milliseconds
                localStorage.setItem('token', data.token);
                localStorage.setItem('token_expiration', expirationTime);
                localStorage.setItem('user', JSON.stringify(data.user));
                setActiveContinueButton(false);
                toggleLoginModal();
                navigate('/session');
            } else {
                const errorData = await response.json();
                console.error('Login failed', errorData);
                setLoginErrorData(errorData);
                // Handle errors (show to user, log, etc.)
            }
        } catch (error) {
            console.error('Login request failed', error);
            // Handle network errors, show user feedback
        }
    };

    const handleRegistration = async () => {
        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: registerEmail,
            phone_number: phoneNumber,
            user_type: user_type,
            password: registerPassword,
            confirm_password: confirmPassword,
            newsletter: newsletterChecked,
            terms_condition: termsChecked,
            age: ageChecked
        };

        try {
            const response = await fetch(`${API_BASE_URL}/register`, { // Adjust this URL to your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setRegisterModal(false);
                setLoginModal(true);
                notifyRegister();
                setRegisterFormErrors({});
            } else {
                const errors = await response.json();
                console.log(errors)
                console.error('Registration failed', errors);
                setRegisterFormErrors(errors);
            }
        } catch (error) {
            console.error('Registration request failed', error);
        }
    };

    const toggleLoginModal = () => {
        setRegisterModal(false);
        setForgotPasswordModal(false)
        setOtpLoginModal(false)
        setLoginModal(!loginModal);
    };

    const toggleRegisterModal = () => {
        // When toggling the registration modal, ensure the login modal is closed to avoid overlaying modals
        setLoginModal(false);
        setTermsAndCondtionalModal(false);
        setForgotPasswordModal(false)
        setOtpLoginModal(false)
        setRegisterModal(!registerModal);
    };

    const toggleTermsAndCondtionsModal = () => {
        setRegisterModal(false);
        setTermsAndCondtionalModal(!termsAndCondtionalModal);
    };

    const toggleOtpLoginModal = () => {
        setLoginModal(false);
        setOtpLoginModal(!otpLoginModal); // Toggle OTP login modal

        
    };
    const toggleForgotPasswordModal = () => {
        setLoginModal(false);
        setForgotPasswordModal(!forgotPasswordModal); 
    
    }// Toggle function for Forgot Password modal

    return (
        <>
            <ToastContainer />
            <div className='app' style={{
                backgroundImage:
                    "url(" + require("assets/img/urps.png") + ")",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: '100%'
            }}>
                {/* <div
                    className="page-header section-dark"
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/urps.png") + ")",
                        backgroundRepeat: 'no-repeat'
                    }}
                > */}

                <div className='container-landing'>
                    <Row className='landing-page-content'>
                        <Col md="4" lg="4"></Col>
                        <Col md="6" sm="6" lg="6">
                            <h1>EXPERIENCE KOSMOSUIT</h1>
                            <ul>
                                <li>Create your own digital spacesuit & explore space, moon, mars & beyond</li>
                                <li>Connect to your existing Kosmosuit</li>
                                <li>Gain valuable bioastronautics insights</li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className="login-register">
                        <Col md="4" lg="4"></Col>
                        <Col className="" lg="6" sm="6" md="6">
                            <Button className='btn-lg' onClick={toggleRegisterModal}>Register</Button>
                            <Button className='btn-lg' onClick={toggleLoginModal}>Login</Button>
                            
                        </Col>
                    </Row>
                </div>
                <div
                    className="moving-clouds"
                    style={{
                        backgroundImage: "url(" + require("assets/img/clouds.png") + ")",
                    }}
                />
                {/* </div> */}
            </div>

            




            {/* login modal */}

            <div className={`modal ${(loginModal) ? 'active' : ''}`}>
                {/* <h3 className="modal-header" /> */}
                <div className="modal-main border">
                    <div className="modal-container">
                        <Row className='mt-5'>
                            <Col className='mt-3' md="12">
                                <Input
                                    className={`mb-3 ${loginErrorData.email ? 'is-invalid' : ''}`}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                    type="email" />

                                <span className='text-danger'>{loginErrorData.email}</span>
                            </Col>
                            <Col className='mt-3' md="12">
                                <Input
                                    className={`mb-3 ${loginErrorData.password ? 'is-invalid' : ''}`}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                    type="password" />
                                <span className='text-danger'>{loginErrorData.password}</span>
                            </Col>
                        </Row>
                        <Row className='mt-5'>
                            <Col md="12" >
                                <Button className='' style={{ width: '100px' }} onClick={handleLogin}>Login</Button>
                                {/* <Button className="continue-button">CONTINUE SESSION</Button> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col md="12">
                                        <span>Don’t have an account ?</span>
                                        <a color="link" style={{ cursor: 'pointer' }} onClick={toggleRegisterModal}> Register</a>
                                    </Col>
                                    <Col className='mt-3' md="12">
                                        <a color="link" style={{ cursor: 'pointer' }} onClick={toggleForgotPasswordModal}> Forgot Password</a>
                                    </Col>
                                    <Col className='mt-3' md="12">
                                        <a color="link" style={{ cursor: 'pointer' }} onClick={toggleOtpLoginModal}> OTP Login</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/* Forgot Password Modal */}
            <div className={`modal ${forgotPasswordModal ? 'active' : ''}`}>
                {/* <h3 className="modal-header" /> */}
                <div className="modal-main border">
                    <div className="modal-container">
                        {/* <Row className='mt-5'>
                            <Col md="12"> */}
                                <ForgotPassword /> {/* Render the ForgotPassword component here */}
                            {/* </Col>
                        </Row> */}
                        
                        <Row>
                            <Col md="6">
                                <a color="link" style={{ cursor: 'pointer' }} onClick={toggleLoginModal}> Login</a>
                            </Col>
                            <Col md="6">
                            <a color="link" style={{ cursor: 'pointer' }} onClick={toggleRegisterModal}> Register</a>
                            </Col>
                        </Row>
                    
                    </div>
                </div>
            </div>


            {/* register modal */}

            <div className={`modal ${(registerModal) ? 'active' : ''}`}>
                {/* <h3 className="modal-header" /> */}
                <div className="modal-main border" style={{ width: "820px" }}>
                    <div className="modal-container">
                        <Row className='mt-5'>

                            <Row>
                                <Col className='mt-1' md="6">
                                    <Input
                                        className={`mb-2 ${registerFormErrors.first_name ? 'is-invalid' : ''}`}
                                        placeholder="First Name"
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    <span className='text-danger'>{registerFormErrors.first_name}</span>
                                </Col>
                                <Col className='mt-1' md="6">
                                    <Input
                                        className={`mb-2 ${registerFormErrors.last_name ? 'is-invalid' : ''}`}
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        type="text"
                                    />
                                    <span className='text-danger'>{registerFormErrors.last_name}</span>
                                </Col>
                                <Col className='mt-1' md="6">
                                    <Input
                                        className={`mb-2 ${registerFormErrors.email ? 'is-invalid' : ''}`}
                                        placeholder="Email"
                                        value={registerEmail}
                                        type="email"
                                        onChange={(e) => setRegisterEmail(e.target.value)}
                                    />
                                    <span className='text-danger'>{registerFormErrors.email}</span>
                                </Col>
                                <Col className='mt-1' md="6">
                                    <Input
                                        className={`mb-2 ${registerFormErrors.phone_number ? 'is-invalid' : ''}`}
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        type="text"
                                    />
                                    <span className='text-danger'>{registerFormErrors.phone_number}</span>
                                </Col>
                                <Col className='mt-1' md="12">
                                    <select
                                        className={`mb-2 ${registerFormErrors.user_type ? 'form-control select-style is-invalid' : 'form-control select-style'}`}
                                        placeholder="Select Role"
                                        name='user_type'
                                        onChange={(e) => setUserType(e.target.value)}
                                    >
                                        <option value="" className="options text-white">Select Role</option>
                                        <option value="Flight Surgeon" className="options text-white">Flight Surgeon</option>
                                        <option value="Researcher" className="options text-white">Researcher</option>
                                        <option value="Astronaut" className="options text-white">Astronaut</option>
                                        <option value="Mission Controller" className="options text-white">Mission Controller</option>
                                        <option value="Suit Engineer" className="options text-white">Suit Engineer</option>
                                    </select>
                                    <span className='text-danger'>{registerFormErrors.user_type}</span>
                                </Col>
                                <Col className='mt-1' md="6">
                                    <Input
                                        className={`mb-2 ${registerFormErrors.password ? 'is-invalid' : ''}`}
                                        placeholder="Password"
                                        type="password"
                                        value={registerPassword}
                                        onChange={(e) => setRegisterPassword(e.target.value)}
                                    />
                                    <span className='text-danger'>{registerFormErrors.password}</span>
                                </Col>
                                <Col className='mt-1' md="6">
                                    <Input
                                        className={`mb-2 ${registerFormErrors.confirm_password ? 'is-invalid' : ''}`}
                                        placeholder="Confirm Password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                    />
                                    <span className='text-danger'>{registerFormErrors.confirm_password}</span>
                                </Col>
                                <Col className='mt-1' md="12">
                                    <div className={`landing-checkbox ${registerFormErrors.newsletter ? 'shake-checkbox' : ''}`}  >
                                        <input
                                            type="checkbox"
                                            id="checkbox1"
                                            required
                                            checked={newsletterChecked}
                                            onChange={handleNewsletterChange}
                                        />
                                        <label for="checkbox1" className={`${registerFormErrors.newsletter ? 'error-checkbox' : ''}`} ></label>
                                        <span className='float-left' style={{ marginLeft: '2em' }}>Receive product news and promotions based on your preferences and registered devices</span>
                                    </div>
                                    {/* {registerFormErrors.newsletter && <span className='text-danger'>{registerFormErrors.newsletter}</span>} */}
                                </Col>
                                <Col className='mt-3' md="12">
                                    <div className={`landing-checkbox ${registerFormErrors.terms_condition ? 'shake-checkbox' : ''}`}>
                                        <input
                                            type="checkbox"
                                            id="checkbox2"
                                            checked={termsChecked}
                                            onChange={handleTermsChange}
                                        />
                                        <label for="checkbox2" className={`${registerFormErrors.terms_condition ? 'error-checkbox' : ''}`}></label>
                                        <span className='float-left' style={{ marginLeft: '2em' }}>I have read and agree to the Terms of Use and Privacy Policy. &nbsp;&nbsp;&nbsp;<a href='#' onClick={() => toggleTermsAndCondtionsModal()}>Terms And Condtions</a></span>
                                    </div>
                                    {/* {registerFormErrors.terms_condition && <span className='text-danger'>{registerFormErrors.terms_condition}</span>} */}
                                </Col>
                                <Col className='mt-3' md="12">
                                    <div className={`landing-checkbox ${registerFormErrors.age ? 'shake-checkbox' : ''}`} >
                                        <input
                                            type="checkbox"
                                            id="checkbox3"
                                            checked={ageChecked}
                                            onChange={handleAgeChange}
                                        />
                                        <label for="checkbox3" className={`${registerFormErrors.age ? 'error-checkbox' : ''}`}></label>
                                        <span className='float-left' style={{ marginLeft: '2em' }}>I am at least 16 years old. </span>
                                    </div>
                                    {/* {registerFormErrors.age && <span className='text-danger'>{registerFormErrors.age}</span>} */}
                                </Col>
                            </Row>
                        </Row>
                        <Button className='mt-3' onClick={handleRegistration}>Register</Button>
                        <Row>
                            <Col md="12">
                                <span>Already have an account ?</span>
                                <a color="link" style={{ cursor: 'pointer' }} onClick={toggleLoginModal}> Login</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/* OTP Login Modal */}
            <div className={`modal ${otpLoginModal ? 'active' : ''}`}>
                {/* <h3 className="modal-header" /> */}
                <div className="modal-main border">
                    <div className="modal-container">
                        {/* <Row className='mt-5'>
                            <Col md="12"> */}
                                <OtpLogin /> {/* Render the OtpLogin component here */}
                            {/* </Col>
                        </Row> */}
                        <Row>
                            <Col md="6">
                                <a color="link" style={{ cursor: 'pointer' }} onClick={toggleLoginModal}> Login</a>
                            </Col>
                            <Col md="6">
                            <a color="link" style={{ cursor: 'pointer' }} onClick={toggleRegisterModal}> Register</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>



            <div className={`modal ${(termsAndCondtionalModal) ? 'active' : ''}`}>
                <div className="modal-main border terms-condition" style={{ width: "60%",height: "600px", overflowY: "auto",overflowX:'hidden' }}>
                    <div className="modal-container" style={{  }}>
                    <Button className="close-button terms-condition-button" style={{width:'40px',height:'40px',position:'relative',left:'360px',bottom:'20px'}} onClick={toggleRegisterModal}>✕</Button>
                        <Row className=''>
                         <PrivacyPolicy />
                        </Row>
                    </div>
                </div>
            </div>

        </>
    )
}
