import React, { useState } from "react";
import {
  FrontFooterDataContext,
} from "contexts/FrontFooterDataContext";

export default function FrontFooterDataWrapper(props) {
  const [userNavigationTrackingState, setUserNavigationTracking] = useState([]);

  const userNavigationTracking = (jsonData) => {

    setUserNavigationTracking(prevState => [...prevState,jsonData])
  }


  return (
    <FrontFooterDataContext.Provider
      value={{ userNavigationTrackingState: userNavigationTrackingState, 
             userNavigationTracking: userNavigationTracking,
               }}
    >
      {props.children}
    </FrontFooterDataContext.Provider>
  );
}






