import { cloneElement } from 'react'

export default function DataLoader({ children, query, page }) {
  const { isLoading, error, data } = query()

  if (isLoading === true) {
    return 'Loading...'
  }

  if (error) {
    return 'An error has occurred: ' + error.message
  }

  if (!data) {
    return `${page} is not yet loaded`
  }

  return cloneElement(children, { data })
}
