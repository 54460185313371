import { useState, forwardRef, useImperativeHandle } from 'react'
import './Modal.css'
import { Box } from 'components'

const Modal = ({ title, children, defaultOpened = false }, ref) => {
  const [isShowing, setIsShowing] = useState(defaultOpened)

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsShowing(true),
      close: () => setIsShowing(false),
      isShowing: isShowing,
    }),
    [isShowing]
  )

  if (!isShowing) {
    return null
  }

  return (
    <div className={`modal active`} onClick={() => setIsShowing(false)}>
      <h3 className="modal-header">{title}</h3>
      <Box className="modal-main" onClick={(e) => e.stopPropagation()}>
        {children}
      </Box>
    </div>
  )
}

export default forwardRef(Modal)
