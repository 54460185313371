import React, { useState } from "react";
import {
  FrontHeaderDataContext,
  headerLabels,
} from "contexts/FrontHeaderDataContext";

export default function FrontHeaderDataWrapper(props) {
  const [isisActive, setIsIsActive] = useState(false);
  const [isLightMode, setIsLightMode] = useState(false);
  const [stageFlight, setStageFlight] = useState(headerLabels.stageFlight);
  const [suitMode,setSuitMode] = useState(headerLabels.suitMode);
  const [cosmosphereMode,setCosmosphereMode] = useState(headerLabels.cosmosphereMode);
  const [activity,setActivity] = useState(headerLabels.activity);
  const [activitiesByFlightStage,setActivityByFlightStage] = useState(headerLabels.activitiesByFlightStage);
  const [changeDashboardView,setchangeDashboardView] = useState(headerLabels.changeDashboardView);

  function changeIsISActiveStatus() {
    setIsIsActive(!isisActive);
  }
  function changeStageFlight(stageFlight) {
    setStageFlight(stageFlight);
  }

  function changeSuitMode(suitMode) {
    setSuitMode(suitMode);
  }

  function changeCosmosphereMode(cosmosphereMode) {
    setCosmosphereMode(cosmosphereMode);
  }

  function changeActivity(activity) {
    setActivity(activity);
  }

  function handleIsLightMode(isLightMode) {
    setIsLightMode(isLightMode);
  }
  function handleDashboards() {
    setchangeDashboardView(!changeDashboardView);
  }

  return (
    <FrontHeaderDataContext.Provider
      value={{ stageFlight: stageFlight, 
               changeStageFlight: changeStageFlight,
               suitMode:suitMode,
               cosmosphereMode:cosmosphereMode, 
               changeSuitMode: changeSuitMode,
               changeCosmosphereMode: changeCosmosphereMode,
               changeIsISActiveStatus: changeIsISActiveStatus,
               handleDashboards: handleDashboards,
               activitiesByFlightStage: activitiesByFlightStage,
               isisActive:isisActive,
               changeDashboardView:changeDashboardView,
               isLightMode:isLightMode,
               handleIsLightMode:handleIsLightMode, 
               activity:activity, changeActivity:changeActivity }}
    >
      {props.children}
    </FrontHeaderDataContext.Provider>
  );
}
