import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from 'Config';
import 'assets/css/sessionsetup.scss';
import { FrontHeaderDataContext } from 'contexts/FrontHeaderDataContext';
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Col,
    CardTitle,
    Table
} from "reactstrap";
import FrontNavbar from 'front/FrontNavbar';
import Header from 'front/Header/Header';


function SessionSetup(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [registerFormErrors, setRegisterFormErrors] = useState({});
    const [loginErrorData, setLoginErrorData] = useState({});

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [activeContinueButton, setActiveContinueButton] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [stageFlightData, setStageFlightData] = useState('Pre Flight');
    const [activityData, setActivityData] = useState('');
    const { stageFlight, suitMode, activity, activitiesByFlightStage, changeStageFlight, changeSuitMode, changeActivity,cosmosphereMode,changeCosmosphereMode } = useContext(FrontHeaderDataContext);

    const notify = () => toast("Your Successfully Login");
    useEffect(() => {
        // Define the function to check if the token is expired
        const isTokenExpired = () => {
            const expirationTime = localStorage.getItem('token_expiration');
            if (!expirationTime) return true;
    
            const currentTime = new Date().getTime();
            return currentTime > parseInt(expirationTime);
        };
    
        // Retrieve the token from localStorage
        const token = localStorage.getItem('token');
    
        // Check if the token exists and is not expired
        if (token && !isTokenExpired()) {
            setIsUserLoggedIn(true);
        } else {
            // Token is either missing or expired, so remove token and related data if any
            localStorage.removeItem('token');
            localStorage.removeItem('token_expiration'); // Ensure to clean up any related data
            setIsUserLoggedIn(false);
            setLoginModal(true); // Show login modal since the user is not logged in or token is expired
            // Use React Router's navigate function to redirect to the main page
            navigate('/'); // Assuming navigate is defined via useNavigate()
        }
    }, []); 

    const createNewSession = async () => {

        let suitID = takeFirstLetters(suitMode, stageFlight, activity) + '-' + getCurrentDate();
        let fligtName  = stageFlight.split(' ').join('').toLowerCase();

        const dataToSend = {
            "suitMode" : suitMode,
            "suitID" : suitID,
            [fligtName]: activity, 
        };

        try {
            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            const response = await fetch(`${API_BASE_URL}/tracking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                },
                body: JSON.stringify(dataToSend)
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const responseData = await response.json();
            navigate('/home');
            console.log('Data successfully sent to the API:', responseData);
        } catch (error) {
            console.error('Error sending data to the API:', error);
        }
    }

    function takeFirstLetters(...args) {
        return args.map(word => word.split(' ').map(w => w.charAt(0)).join('')).join('-');
    }

    function getCurrentDate() {
        let today = new Date();
        let month = (today.getMonth() + 1).toString().padStart(2, '0');
        let day = today.getDate().toString().padStart(2, '0');
        let year = today.getFullYear();
        return month + day + year;
    }

    const handleLogin = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Login successful', data);
                notify();
                // Save token to localStorage or context/state
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                setActiveContinueButton(false);
                toggleLoginModal(); // Close the modal upon successful login
                // Update UI state to reflect that user is logged in
            } else {
                const errorData = await response.json();
                console.error('Login failed', errorData);
                setLoginErrorData(errorData);
                // Handle errors (show to user, log, etc.)
            }
        } catch (error) {
            console.error('Login request failed', error);
            // Handle network errors, show user feedback
        }
    };

    const handleRegistration = async () => {
        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: registerEmail,
            phone_number: phoneNumber,
            password: registerPassword,
            confirm_password: confirmPassword,
        };
    
        try {
            const response = await fetch(`${API_BASE_URL}/register`, { // Adjust this URL to your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setRegisterModal(false); 
                setLoginModal(true);
                setRegisterFormErrors({});
            } else {
                const errors = await response.json();
                console.log(errors)
                console.error('Registration failed', errors);
                setRegisterFormErrors(errors);
            }
        } catch (error) {
            console.error('Registration request failed', error);
        }
    };

    const toggleLoginModal = () => {
        // console.log('welcome')
        setRegisterModal(false);
        setLoginModal(!loginModal);
    };

    const toggleRegisterModal = () => {
        // When toggling the registration modal, ensure the login modal is closed to avoid overlaying modals
        setLoginModal(false);
        setRegisterModal(!registerModal);
    };


    const handleStageFlightChange = (fligt) => {
        changeStageFlight(fligt);
        setStageFlightData(fligt);
        setActivityData('');
    };


    const handleSuitModeChange = (suit) => {
        changeSuitMode(suit);
    };

    const handleCosmosphereMode = (mode) => {
        changeCosmosphereMode(mode);
    }

    const handleActivityChange = (active) => {
        changeActivity(active);
        setActivityData(active);
    };
    return (
        <div>
            <ToastContainer />
            {/* <FrontNavbar currentRoute={location.pathname}></FrontNavbar> */}
            <Header currentRoute={location.pathname}></Header>


            <main className="content" style={{ background: '#282c34', height: '100vh' }}>
                <div className="session-container">
                    <label className="session-title">Welcome</label>
                    <div className="session-body">

                        <div className="list-menu-container">
                            <label className="card-title text-white"> CHOOSE SUIT MODE</label>
                            <div className="card-content list-menu-content">
                                <main>
                                    <Row>
                                        <Col className='mt-5' md="6">
                                        <button className={`default-label ${suitMode === 'Virtual Suit' ? 'selected' : ''}`} onClick={() => handleSuitModeChange('Virtual Suit')}>Virtual Suit</button>
                                        </Col>
                                        <Col className='mt-5' md="6">
                                        <button className={`default-label ${suitMode === 'Real Suit' ? 'selected' : ''}`} onClick={() => handleSuitModeChange('Real Suit')}>Real Suit</button>
                                        </Col>
                                    </Row>
                                        <Row>
                                            <Col className='mt-5' md="12">
                                            <button className={`default-label ${cosmosphereMode === 'Land' ? 'selected' : ''}`} onClick={() => handleCosmosphereMode('Land')}>Land</button>
                                            </Col>
                                            <Col className='mt-5' md="12">
                                            <button className={`default-label ${cosmosphereMode === 'Air' ? 'selected' : ''}`} onClick={() => handleCosmosphereMode('Air')}>Air</button>
                                            </Col>
                                            <Col className='mt-5' md="12">
                                            <button className={`default-label ${cosmosphereMode === 'Sea' ? 'selected' : ''}`} onClick={() => handleCosmosphereMode('Sea')}>Sea</button>
                                            </Col>
                                            <Col className='mt-5' md="12">
                                            <button className={`default-label ${cosmosphereMode === 'Space' ? 'selected' : ''}`} onClick={() => handleCosmosphereMode('Space')}>Space</button>
                                            </Col>
                                        </Row>
                                </main>
                            </div>
                        </div>

                        <div className="list-menu-container">
                            <label className="card-title text-white">CHOOSE STAGE OF FLIGHT</label>
                            <div className="card-content list-menu-content">
                                <main>
                                    <button className={`default-label ${stageFlight === 'Pre Flight' ? 'selected' : ''}`} onClick={() => handleStageFlightChange('Pre Flight')}>Pre Flight</button>
                                    <button className={`default-label ${stageFlight === 'In Flight' ? 'selected' : ''}`} onClick={() => handleStageFlightChange('In Flight')}>In Flight</button>
                                    <button className={`default-label ${stageFlight === 'Post Flight' ? 'selected' : ''}`} onClick={() => handleStageFlightChange('Post Flight')}>Post Flight</button>
                                </main>
                            </div>
                        </div>

                        <div className="list-menu-container">
                            <label className="card-title text-white">CHOOSE ACTIVITY</label>
                            <div className="card-content list-menu-content">
                                <main className='activity-menu' style={{ overflowY: 'auto', height: '50vh' }}>
                                    {activitiesByFlightStage[stageFlight].map((act, index) => (
                                        <button key={index} className={`default-label ${activity === act ? 'selected' : ''}`} onClick={() => handleActivityChange(act)}>{act}</button>
                                    ))}
                                </main>
                            </div>
                        </div>
                    </div>
                    {/* /kosmosuit */}
                    <Button disabled={activeContinueButton} onClick={() => {createNewSession()}} className="session-continue-button">continue</Button>
                </div>
            </main>

            {/* Login Modal */}
            {/* <Modal isOpen={loginModal} toggle={toggleLoginModal}>
            <ModalHeader toggle={toggleLoginModal}>Login</ModalHeader>
            <ModalBody>
                <Row className='mt-5'>
                    <Col className='mt-3' md="12">
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            style={{color:'#000'}}
                            type="email" />
                    </Col>
                    <Col className='mt-3' md="12">
                        <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            style={{color:'#000'}}
                            type="password" />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleLogin}>Login</Button>
                <Button color="secondary" onClick={toggleLoginModal}>Cancel</Button>
                <Button color="link" onClick={toggleRegisterModal}>Register</Button>
                
            </ModalFooter>
        </Modal> */}

            <div className={`modal ${(loginModal) ? 'active' : ''}`}>
                <h3 className="modal-header" />
                <div className="modal-main border">
                    <div className="modal-container">
                        <Row className='mt-5'>
                            <Col className='mt-3' md="12">
                                <Input
                                    className='mb-3'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                    type="email" />
                                    
                                    <span className='text-danger'>{loginErrorData.email}</span>
                            </Col>
                            <Col className='mt-3' md="12">
                                <Input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                    type="password" />
                                    <span className='text-danger'>{loginErrorData.password}</span>
                            </Col>
                        </Row>
                        <Row className='mt-5'>
                            <Col md="12" >
                                <Button className='' style={{ width: '100px' }} onClick={handleLogin}>Login</Button>
                                {/* <Button className="continue-button">CONTINUE SESSION</Button> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <span>Don’t have an account ?</span>
                                <a color="link" style={{cursor:'pointer'}} onClick={toggleRegisterModal}> Register</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/* Registration Modal */}
            {/* <Modal isOpen={registerModal} toggle={toggleRegisterModal}>
                <ModalHeader toggle={toggleRegisterModal}>Register</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col className='mt-3' md="12"><Input placeholder="First Name" type="text" /></Col>
                        <Col className='mt-3' md="12"><Input placeholder="Last Name" type="text" /></Col>
                        <Col className='mt-3' md="12"><Input placeholder="Email" type="email" /></Col>
                        <Col className='mt-3' md="12"><Input placeholder="Phone Number" type="text" /></Col>
                        <Col className='mt-3' md="12"><Input placeholder="Password" type="password" /></Col>
                        <Col className='mt-3' md="12"><Input placeholder="Confirm Password" type="password" /></Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleRegistration}>Register</Button>{' '}
                    <Button color="secondary" onClick={toggleRegisterModal}>Cancel</Button>
                </ModalFooter>
            </Modal> */}

            <div className={`modal ${(registerModal) ? 'active' : ''}`}>
                <h3 className="modal-header" />
                <div className="modal-main border">
                    <div className="modal-container">
                        <Row className='mt-5'>

                            <Row>
                                <Col className='mt-3' md="12">
                                    <Input placeholder="First Name"  type="text" 
                                           value={firstName} 
                                           onChange={(e) => setFirstName(e.target.value)} 
                                    />
                                    <span className='text-danger'>{registerFormErrors.first_name}</span>
                                    </Col>
                                <Col className='mt-3' md="12">
                                    <Input placeholder="Last Name" 
                                           value={lastName} 
                                           onChange={(e) => setLastName(e.target.value)} 
                                           type="text" 
                                    />
                                    <span className='text-danger'>{registerFormErrors.last_name}</span>
                                    </Col>
                                <Col className='mt-3' md="12">
                                    <Input placeholder="Email" 
                                           value={registerEmail} 
                                           type="email"
                                           onChange={(e) => setRegisterEmail(e.target.value)}
                                        />
                                        <span className='text-danger'>{registerFormErrors.email}</span>
                                </Col>
                                <Col className='mt-3' md="12">
                                    <Input placeholder="Phone Number"
                                           value={phoneNumber}
                                           onChange={(e) => setPhoneNumber(e.target.value)} 
                                           type="text" 
                                    />
                                    <span className='text-danger'>{registerFormErrors.phone_number}</span>
                                           </Col>
                                <Col className='mt-3' md="12">
                                    <Input placeholder="Password" 
                                           type="password"
                                           value={registerPassword}
                                           onChange={(e) => setRegisterPassword(e.target.value)} 
                                        />
                                        <span className='text-danger'>{registerFormErrors.password}</span>
                                    </Col>
                                <Col className='mt-3' md="12">
                                    <Input placeholder="Confirm Password" 
                                           type="password" 
                                           value={confirmPassword}
                                           onChange={(e) => {setConfirmPassword(e.target.value)}}
                                        />
                                        <span className='text-danger'>{registerFormErrors.confirm_password}</span>
                                </Col>
                            </Row>
                        </Row>
                        <Button className='mt-3'  onClick={handleRegistration}>Register</Button>
                        <Row>
                            <Col md="12">
                                <span>Already have an account ?</span>
                                <a color="link" style={{cursor:'pointer'}} onClick={toggleLoginModal}> Login</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SessionSetup;