import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation ,Link} from 'react-router-dom';
import './EmailVerified.css'; // Assuming you'll style this component
import { API_BASE_URL } from 'Config'; // Ensure this is correctly set

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const location = useLocation(); // Access query string parameters

  useEffect(() => {
    // Extract token from query string
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    // If token exists, verify email
    if (token) {
      verifyEmail(token);
    } else {
      setError('Verification token is missing.');
    }
  }, [location]);

  // Function to verify email
  const verifyEmail = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/verify-email?token=${token}`);
      setMessage(response.data.message);
    } catch (error) {
      if (error.response && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('An error occurred while verifying your email.');
      }
    }
  };

  return (
    <div className="verify-email-card-wrapper">
      <div className="verify-email-card">
        {message && (
          <>
            <h1>Email Verified!</h1>
            <p>{message}</p>
            <Link to="/" className="verify-email-card-btn">Go to Login</Link>
          </>
        )}
        {error && (
          <>
            <h1>Verification Failed</h1>
            <p style={{ color: 'red' }}>{error}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
