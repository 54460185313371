import React from 'react';
import '../assets/css/custom.css';
import classNames from "classnames";
import { Link } from 'react-router-dom';
import logo from "assets/img/Kosmosuit_logo.png";
// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
} from "reactstrap";

function FrontNavbar(props) {

    const [collapseOpen, setcollapseOpen] = React.useState(false);
    const [color, setcolor] = React.useState("navbar-dark");
    const [navbarName, setNavbarName] = React.useState("Digital Fit");  // State for dynamically changing navbar name

    React.useEffect(() => {
        window.addEventListener("resize", updateColor);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.removeEventListener("resize", updateColor);
        };
    });

    React.useEffect(() => {
        let name = "Home";  // Default name
    
        // Remove the leading slash from the current route
        const routeWithoutSlash = props.currentRoute.replace(/^\/|\/$/g, '');
        switch (routeWithoutSlash) {
            case 'digital-fit':
                name = "Digital Fit";
                break;
            case 'simulate':
                name = "Simulate";
                break;
            case 'simulate/view':
                name = "Simulate";
                break;
            case 'simulate/analytics':
                name = "Simulate";
                break;
            case 'dashboard':
                name = "Dashboard";
                break;
            // Add more cases as needed for different routes
            default:
                // Use the default name if the route doesn't match any specific case
                break;
        }
    
        setNavbarName(name);
    }, [props.currentRoute]);
    // function that adds color white/dark to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
            setcolor("bg-white");
        } else {
            setcolor("navbar-dark");
        }
    };
    // this function opens and closes the collapse on small devices
    const toggleCollapse = () => {
        if (collapseOpen) {
            setcolor("navbar-dark");
        } else {
            setcolor("bg-white");
        }
        setcollapseOpen(!collapseOpen);
    };

    return (
        // <div>
            <Navbar className={classNames("navbar-absolute mb-4", color)} expand="lg" style={{ background: '#14181B' }}>
                <Container fluid>
                    <Nav className="ml-auto front-navbar">
                            <li className="mx-2">
                                <Link to="/"><i style={{ color: '#fff' }} className='fa fa-home'></i></Link>
                            </li>
                            <li className="" style={{ borderLeft: '1px solid #6a6d6f' }}>
                                <a href='#'><img src={logo} width={60} height={'auto'} alt="Logo"></img></a>
                            </li>
                            <li className="" style={{ borderLeft: '1px solid #6a6d6f' }}>
                                &nbsp;&nbsp;&nbsp;<a href='#' style={{ color: '#fff' }}>{navbarName}</a>
                            </li>
                    </Nav>
                    <NavbarToggler onClick={toggleCollapse}>
                        <span className="navbar-toggler-bar bar1" />
                        <span className="navbar-toggler-bar bar1" />
                        <span className="navbar-toggler-bar bar1" />
                    </NavbarToggler>



                    <Collapse navbar isOpen={collapseOpen}>
                        <Nav className="m-auto" navbar>
                          <li><a href='#'>In Flight <i className='fa fa-angle-down'></i></a></li>
                          <li><a href='#'>Virtual <i className='fa fa-angle-down'></i></a></li>
                          <li><a href='#'>Experience <i className='fa fa-angle-down'></i></a></li>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar> 
        // </div>
    );
}

export default FrontNavbar;