import './Metric.scss'

export default function Metric({ value, unit, label }) {
  return (
    <div className="metric-container">
      <div className={label === 'battery life' ? 'battery-metric-font' : ''}>
        <label className="value">{value}</label>
        <label className="unit">{unit}</label>
      </div>
      <label className="label">{label}</label>
    </div>
  )
}
