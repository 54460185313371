import React, { useEffect } from 'react';
import {
  SciChartSurface,
  NumericAxis,
  FastLineRenderableSeries,
  XyDataSeries,
  SciChartJsNavyTheme,
  EAutoRange,
  NumberRange,
  RolloverModifier,
  translateFromSeriesViewRectToCanvas,
  Point
} from 'scichart';

class CustomPlacementRollover extends RolloverModifier {
    constructor() {
    super();
    }
  
    // do nothing (disable default behavior)
    modifierMouseMove(e) { }
    modifierMouseLeave(e) { }
  
    onParentSurfaceRendered() {
  
    const xAxis = this.parentSurface?.xAxes?.getById(this.xAxisId);
    if (xAxis && this.xValue && this.parentSurface?.seriesViewRect) {
      // Convert xValue from data to coordinate.
      const xCoord = xAxis.getCurrentCoordinateCalculator()?.getCoordinate(this.xValue);
      // Translate from the seriesViewRect back to the parent canvas (rollover expects coords in this space)
      const hackedMousePoint = translateFromSeriesViewRectToCanvas(new Point(xCoord, 0), this.parentSurface.seriesViewRect);
      // Simulate rollover at x,y coord
      console.log(`Simulating a mouse move to (x,y) = ${hackedMousePoint?.toString()}`);
      super.modifierMouseMove({ mousePoint: hackedMousePoint });
    }
  
    super.onParentSurfaceRendered();
    }
  
    setXValue(xValue) {
    console.log(`Setting XValue to ${xValue}`);
    this.xValue = xValue;
    }
  }

const SciChartExample = () => {
  useEffect(() => {
    const rolloverProgrammaticPlacement = async () => {
      const { sciChartSurface, wasmContext } = await SciChartSurface.create("scichart-example", {
        theme: new SciChartJsNavyTheme(),
        title: "Place Rollover at X=10 on a dynamic chart",
        titleStyle: { fontSize: 16 },
      });

      sciChartSurface.xAxes.add(new NumericAxis(wasmContext, { autoRange: EAutoRange.Always, axisTitle: "X Axis" }));
      sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { visibleRange: new NumberRange(-2, 0.5), axisTitle: "Y Axis" }));

      const rollover = new CustomPlacementRollover();
      rollover.rolloverLineStrokeDashArray = [2, 2];
      rollover.rolloverLineStroke = "SteelBlue";
      rollover.rolloverLineStrokeThickness = 2;
      rollover.setXValue(10);
      sciChartSurface.chartModifiers.add(rollover);

      const xyDataSeries = new XyDataSeries(wasmContext, {
        xValues: [],
        yValues: [],
      });

      sciChartSurface.renderableSeries.add(new FastLineRenderableSeries(wasmContext, {
        dataSeries: xyDataSeries,
        strokeThickness: 3,
        stroke: "#50C7E0",
      }));

      const updateCallback = () => {
        const xValues = [];
        const yValues = [];
        for (let i = 0; i < 100; i++) {
          xValues.push(i);
          yValues.push(Math.random() * Math.sin(i * 0.1) - Math.cos(i * 0.01));
        }
        xyDataSeries.clear();
        xyDataSeries.appendRange(xValues, yValues);
      };

      setTimeout(() => {
        updateCallback();
        setInterval(updateCallback, 500);
      }, 20);
    };

    rolloverProgrammaticPlacement();
  }, []);

  return (
    <div id="scichart-example" 
        style={{
          width: '100%', 
          minHeight: '25vh', // Ensures the chart has a minimum height of 50% of the viewport height
          height: 'auto' // Optional: allows the container to grow taller than the minHeight if content requires
        }}
    ></div>
  );
};

export default SciChartExample;
