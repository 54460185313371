import { useRef, useEffect, useLayoutEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

function HelmetModelView({ src }) {
    const helmetModelRef = useRef();
    const controlsRef = useRef();

    const { scene } = useGLTF(src);

    useLayoutEffect(() => {
        if (helmetModelRef.current && scene) {
            console.log(`Updating model for source: ${src}`);

                    helmetModelRef.current.position.y = -12.5;
                    helmetModelRef.current.scale.set(55, 55, 55);
            console.log(`Positionhel: ${helmetModelRef.current.position.y}, Scale: ${helmetModelRef.current.scale.x}`);
            // Force R3F to update the scene
            // This step might not be necessary but is here for demonstration
            helmetModelRef.current.needsUpdate = true;
        }
    }, [scene, src]);

    return (
        <>
            <primitive object={scene} ref={helmetModelRef} />
            <OrbitControls ref={controlsRef} enableZoom={true} />
        </>
    );
}

export default HelmetModelView;