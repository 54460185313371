import { DragDropContainer } from 'react-drag-drop-container'
import './MenuList.scss'

const MenuItem = ({ item, onSelected, checkIsDisabled = () => {}, isActive, toneDownDisabled, icon }) => {
  const handleMenuItemChange = () => {
    if (onSelected) {
      onSelected(icon ? { id: item.id, name: item.name, icon: icon, typeTag: item.typeTag } : item)
    }
  }
  return (
    <li
      className={`${isActive ? 'active' : ''} ${
        toneDownDisabled && checkIsDisabled(item.name || item) ? 'disabled' : ''
      }`}>
      <button disabled={checkIsDisabled(item.name || item)} onClick={handleMenuItemChange}>
        {' '}
        {item.name || item}
      </button>
    </li>
  )
}

const MenuList = ({ data, onSelected, checkIsDisabled = () => {}, activeItem, toneDownDisabled = false, icon }) => {
  return (
    <menu className="menu-list">
      {Array.isArray(data) ? (
        data.map((item, index) => {
          return (
            <DragDropContainer
              targetKey="metric"
              dragData={{ ...item, icon: icon }}
              key={index}
              onDrag={() => console.clear()}>
              <MenuItem
                isActive={
                  Array.isArray(activeItem)
                    ? activeItem.findIndex((metric) => metric.id === (item.id || item)) !== -1
                    : (item.name || item) === activeItem
                }
                key={item.id || index}
                item={item}
                icon={icon}
                onSelected={onSelected}
                toneDownDisabled={toneDownDisabled}
                checkIsDisabled={() => checkIsDisabled(item.id || item)}
              />
            </DragDropContainer>
          )
        })
      ) : (
        <DragDropContainer targetKey="metric" dragData={{ ...data, icon: icon }} onDrag={() => console.clear()}>
          <MenuItem
            isActive={
              Array.isArray(activeItem)
                ? activeItem.findIndex((metric) => metric.id === data?.id) !== -1
                : data?.id === activeItem
            }
            key={data.id}
            icon={icon}
            item={data}
            onSelected={onSelected}
            toneDownDisabled={toneDownDisabled}
            checkIsDisabled={() => checkIsDisabled(data?.id)}
          />
        </DragDropContainer>
      )}
    </menu>
  )
}

export default MenuList
