import { useEffect, useState } from 'react'
import { ACTIVE_SESSION_KEY, SESSIONS_KEY } from 'common/constants'
import { results } from './Resources'
import './SaveAsModal.scss'

export default function SaveAsModal({ handleClose, metricStack }) {
  const [fileName, setFileName] = useState('')
  const [fileNameExists, setFileNameExists] = useState(false)
  const metricData = results?.filter((item) => metricStack?.findIndex((metric) => metric.id === item.id) !== -1)
  const activeSession = JSON.parse(localStorage.getItem(ACTIVE_SESSION_KEY)) ?? null
  const storedSessions = JSON.parse(localStorage.getItem(SESSIONS_KEY))
  const isSessionExists = storedSessions.find((item) => item.name === fileName)

  useEffect(() => {
    if (!fileName || !isSessionExists) {
      setFileNameExists(false)
    }
  }, [fileName, isSessionExists])

  const exportToJsonFile = (jsonData) => {
    const saveJsonData = jsonData.length
      ? { ...activeSession, metrics: jsonData, name: fileName }
      : { ...activeSession, name: fileName }
    const dataStr = JSON.stringify(saveJsonData)
    const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr)
    const linkElement = document.createElement('a')
    linkElement.setAttribute('href', dataUri)
    linkElement.setAttribute('download', `${fileName}.json`)
    linkElement.click()
  }

  const onModalClose = () => {
    setFileName('')
    handleClose()
  }

  const onClose = (name) => {
    if (name === 'save' && !isSessionExists) {
      exportToJsonFile(metricData)
      onModalClose()
    } else if (name === 'save' && isSessionExists) {
      setFileNameExists(true)
    } else {
      onModalClose()
    }
  }

  return (
    <div className="save-As-modal">
      <input
        placeholder="Enter File Name"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
        maxLength={15}
      />
      {fileNameExists && (
        <div className="validation">
          <label>Session name already exists</label>
        </div>
      )}
      <div>
        <button className="cancel-button" onClick={onClose}>
          cancel
        </button>
        <button name="save" disabled={!fileName} onClick={(e) => onClose(e.target.name)}>
          save
        </button>
      </div>
    </div>
  )
}
