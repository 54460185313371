import React, { useEffect } from 'react';
import {
  SciChartSurface,
  NumericAxis,
  FastCandlestickRenderableSeries,
  OhlcDataSeries,
  SciChartJsNavyTheme,
  makeIncArray,
  ECoordinateMode,
  EAxisAlignment,
  FastColumnRenderableSeries,
  XyDataSeries,
  Rect,
  ENumericFormat,
  MouseWheelZoomModifier,
  ZoomPanModifier,
  ZoomExtentsModifier,
} from 'scichart';

const getCandles = async (
    symbol,
    interval,
    limit = 300
) => {
  let url = `https://api.binance.us/api/v3/klines?symbol=${symbol}&interval=${interval}`;
  if (limit) {
    url += `&limit=${limit}`;
  }
  try {
    console.log(`SimpleBinanceClient: Fetching ${limit} candles of ${symbol} ${interval}`);
    const response = await fetch(url);
    // Returned data format is [ { date, open, high, low, close, volume }, ... ]
    const data = await response.json();
    // Map to { dateValues[], openValues[], highValues[], lowValues[], closeValues[] } expected by scichart.js
    const dateValues = [];
    const openValues = [];
    const highValues = [];
    const lowValues = [];
    const closeValues = [];
    const volumeValues = [];
    data.forEach(candle => {
      const [timestamp, open, high, low, close, volume] = candle;
      dateValues.push(timestamp / 1000); // SciChart expects Unix Timestamp / 1000
      openValues.push(parseFloat(open));
      highValues.push(parseFloat(high));
      lowValues.push(parseFloat(low));
      closeValues.push(parseFloat(close));
      volumeValues.push(parseFloat(volume));
    });
    return { dateValues, openValues, highValues, lowValues, closeValues, volumeValues };
  } catch (err) {
    console.error(err);
    return [];
  }
};
const SciChartsExampleFour = () => {
  useEffect(() => {
    const volumeProfile = async () => {
      const { wasmContext, sciChartSurface } = await SciChartSurface.create("scichart-four", {
        theme: new SciChartJsNavyTheme(),
      });

      sciChartSurface.xAxes.add(new NumericAxis(wasmContext, { labelFormat: ENumericFormat.Date_HHMMSS }));
      sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { labelPrefix: "$", labelPrecision: 2 }));

      const { dateValues, openValues, highValues, lowValues, closeValues, volumeValues } = await getCandles("BTCUSDT", "1h", 20);

      const dataSeries = new OhlcDataSeries(wasmContext, {
        xValues: dateValues,
        openValues,
        highValues,
        lowValues,
        closeValues,
      });

      const candlestickSeries = new FastCandlestickRenderableSeries(wasmContext, {
        strokeThickness: 1,
        dataSeries,
        dataPointWidth: 0.7,
        brushUp: "#33ff3377",
        brushDown: "#ff333377",
        strokeUp: "#77ff77",
        strokeDown: "#ff7777",
      });
      sciChartSurface.renderableSeries.add(candlestickSeries);

      const width = (dateValues[1] - dateValues[0]) * 0.8;

      for (let i = 0; i < dateValues.length; i++) {
        const height = highValues[i] - lowValues[i];
        const numbars = Math.round(height / 10);
        const xVolValues = makeIncArray(numbars);

        const subChart = await sciChartSurface.addSubChart({ coordinateMode: ECoordinateMode.DataValue, position: new Rect(dateValues[i], highValues[i], width, height) });

        const xAxis = new NumericAxis(wasmContext, { isVisible: false, axisAlignment: EAxisAlignment.Left });
        subChart.xAxes.add(xAxis);

        const yAxis = new NumericAxis(wasmContext, { isVisible: false, axisAlignment: EAxisAlignment.Bottom, flippedCoordinates: true });
        subChart.yAxes.add(yAxis);

        const subDS = new XyDataSeries(wasmContext, { xValues: xVolValues, yValues: xVolValues.map(v => Math.exp(-Math.pow(v - numbars / 2, 2) / 20) + Math.random() * 0.5) });
        const subSeries = new FastColumnRenderableSeries(wasmContext, { dataSeries: subDS, opacity: 0.5, strokeThickness: 0 });
        subChart.renderableSeries.add(subSeries);
      }

      sciChartSurface.chartModifiers.add(new MouseWheelZoomModifier());
      sciChartSurface.chartModifiers.add(new ZoomPanModifier());
      sciChartSurface.chartModifiers.add(new ZoomExtentsModifier());
    };

    volumeProfile();
  }, []);

  return <div id="scichart-four" 
              style={{
                width: '100%', 
                minHeight: '25vh', // Ensures the chart has a minimum height of 50% of the viewport height
                height: 'auto' // Optional: allows the container to grow taller than the minHeight if content requires
              }}
              ></div>;
};

export default SciChartsExampleFour;
