import { useEffect } from 'react';

const UrpsRedirect = () => {
  useEffect(() => {
    // Redirect to the external URL
    window.location.href = 'http://localhost:3001/urps';
  }, []);

  return null;
};

export default UrpsRedirect;