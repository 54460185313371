import React, { useEffect, useState, useRef } from "react";
import { vitalSignsEcgData } from "./vitalSignsEcgData";
import {
    CategoryAxis,
    EllipsePointMarker,
    FastLineRenderableSeries,
    NumberRange,
    NumericAxis,
    RightAlignedOuterVerticallyStackedAxisLayoutStrategy,
    SciChartSurface,
    XyDataSeries,
    SciChartJsNavyTheme,
} from "scichart";

const divElementIds = ["chart1", "chart2", "chart3"]; // IDs for the three charts
const STEP = 10;
const TIMER_TIMEOUT_MS = 20;
const STROKE_THICKNESS = 4;
const POINTS_LOOP = 5200;
const GAP_POINTS = 50;
const DATA_LENGTH = vitalSignsEcgData.xValues.length;

const {
    ecgHeartRateValues,
    bloodPressureValues,
    bloodVolumeValues,
    bloodOxygenationValues
} = vitalSignsEcgData;

const getValuesFromData = (xIndex) => {
    const xArr = [];
    const ecgHeartRateArr = [];
    const bloodPressureArr = [];
    const bloodVolumeArr = [];
    const bloodOxygenationArr = [];
    for (let i = 0; i < STEP; i++) {
        const dataIndex = (xIndex + i) % DATA_LENGTH;
        const x = xIndex + i;
        xArr.push(x);
        ecgHeartRateArr.push(ecgHeartRateValues[dataIndex]);
        bloodPressureArr.push(bloodPressureValues[dataIndex]);
        bloodVolumeArr.push(bloodVolumeValues[dataIndex]);
        bloodOxygenationArr.push(bloodOxygenationValues[dataIndex]);
    }
    return {
        xArr,
        ecgHeartRateArr,
        bloodPressureArr,
        bloodVolumeArr,
        bloodOxygenationArr
    };
};

const drawExample = async (
    divElementId,
    setInfoEcg,
    setInfoBloodPressure1,
    setInfoBloodPressure2,
    setInfoBloodVolume,
    setInfoBloodOxygenation
) => {
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(divElementId, {
        // Apply your own theme here
        theme: new SciChartJsNavyTheme()
    });

    const xAxis = new CategoryAxis(wasmContext, {
        visibleRange: new NumberRange(0, POINTS_LOOP),
        isVisible: false
    });
    sciChartSurface.xAxes.add(xAxis);

    const yAxisHeartRate = new NumericAxis(wasmContext, {
        id: "yHeartRate",
        visibleRange: new NumberRange(0.7, 1.0),
        isVisible: false
    });
    const yAxisBloodPressure = new NumericAxis(wasmContext, {
        id: "yBloodPressure",
        visibleRange: new NumberRange(0.4, 0.8),
        isVisible: false
    });
    const yAxisBloodVolume = new NumericAxis(wasmContext, {
        id: "yBloodVolume",
        visibleRange: new NumberRange(0.1, 0.5),
        isVisible: false
    });
    const yAxisBloodOxygenation = new NumericAxis(wasmContext, {
        id: "yBloodOxygenation",
        visibleRange: new NumberRange(0, 0.2),
        isVisible: false
    });
    sciChartSurface.layoutManager.rightOuterAxesLayoutStrategy = new RightAlignedOuterVerticallyStackedAxisLayoutStrategy();
    sciChartSurface.yAxes.add(yAxisHeartRate, yAxisBloodPressure, yAxisBloodVolume, yAxisBloodOxygenation);

    const fifoSweepingGap = GAP_POINTS;
    const dataSeries1 = new XyDataSeries(wasmContext, {
        fifoCapacity: POINTS_LOOP,
        fifoSweeping: true,
        fifoSweepingGap
    });
    const dataSeries2 = new XyDataSeries(wasmContext, {
        fifoCapacity: POINTS_LOOP,
        fifoSweeping: true,
        fifoSweepingGap
    });
    const dataSeries3 = new XyDataSeries(wasmContext, {
        fifoCapacity: POINTS_LOOP,
        fifoSweeping: true,
        fifoSweepingGap
    });
    const dataSeries4 = new XyDataSeries(wasmContext, {
        fifoCapacity: POINTS_LOOP,
        fifoSweeping: true,
        fifoSweepingGap
    });

    const pointMarkerOptions = {
        width: 7,
        height: 7,
        strokeThickness: 2,
        fill: "red", // Apply your own color here
        lastPointOnly: true
    };

    sciChartSurface.renderableSeries.add(
        new FastLineRenderableSeries(wasmContext, {
            yAxisId: yAxisHeartRate.id,
            strokeThickness: STROKE_THICKNESS,
            stroke: "red", // Apply your own color here
            dataSeries: dataSeries1,
            pointMarker: new EllipsePointMarker(wasmContext, { ...pointMarkerOptions, stroke: "red" }) // Apply your own color here
        })
    );

    // Add other renderable series in a similar manner
sciChartSurface.renderableSeries.add(
    new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisBloodPressure.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: "blue", // Apply your own color here
        dataSeries: dataSeries2,
        pointMarker: new EllipsePointMarker(wasmContext, { ...pointMarkerOptions, stroke: "blue" }) // Apply your own color here
    })
);

sciChartSurface.renderableSeries.add(
    new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisBloodVolume.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: "green", // Apply your own color here
        dataSeries: dataSeries3,
        pointMarker: new EllipsePointMarker(wasmContext, { ...pointMarkerOptions, stroke: "green" }) // Apply your own color here
    })
);

sciChartSurface.renderableSeries.add(
    new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisBloodOxygenation.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: "orange", // Apply your own color here
        dataSeries: dataSeries4,
        pointMarker: new EllipsePointMarker(wasmContext, { ...pointMarkerOptions, stroke: "orange" }) // Apply your own color here
    })
);


    let timerId;

    const runUpdateDataOnTimeout = () => {
        const { xArr, ecgHeartRateArr, bloodPressureArr, bloodVolumeArr, bloodOxygenationArr } = getValuesFromData(
            currentPoint
        );
        currentPoint += STEP;

        dataSeries1.appendRange(xArr, ecgHeartRateArr);
        dataSeries2.appendRange(xArr, bloodPressureArr);
        dataSeries3.appendRange(xArr, bloodVolumeArr);
        dataSeries4.appendRange(xArr, bloodOxygenationArr);

        if (currentPoint % 1000 === 0) {
            const ecg = ecgHeartRateArr[STEP - 1];
            setInfoEcg(Math.floor(ecg * 20));
            const bloodPressure = bloodPressureArr[STEP - 1];
            setInfoBloodPressure1(Math.floor(bloodPressure * 46));
            setInfoBloodPressure2(Math.floor(bloodPressure * 31));
            const bloodVolume = bloodVolumeArr[STEP - 1] + 3;
            setInfoBloodVolume(bloodVolume + 8.6);
            const bloodOxygenation = bloodOxygenationArr[STEP - 1];
            setInfoBloodOxygenation(Math.floor(bloodOxygenation * 10 + 93));
        }
        timerId = setTimeout(runUpdateDataOnTimeout, TIMER_TIMEOUT_MS);
    };

    const handleStop = () => {
        clearTimeout(timerId);
        timerId = undefined;
    };

    const handleStart = () => {
        if (timerId) {
            handleStop();
        }
        runUpdateDataOnTimeout();
    };

    return { sciChartSurface, wasmContext, controls: { handleStart, handleStop } };
};

let currentPoint = 0;

export default function VitalSignsMonitorDemo() {
    const sciChartSurfaces = useRef([]);
    const controlsRefs = useRef([]);

    const [infos, setInfos] = useState({
        infoEcg: 0,
        infoBloodPressure1: 0,
        infoBloodPressure2: 0,
        infoBloodVolume: 0,
        infoBloodOxygenation: 0
    });

    useEffect(() => {
        const initializeCharts = async () => {
            divElementIds.forEach((divId, index) => {
                drawExample(
                    divId,
                    (value) => setInfos(prev => ({ ...prev, infoEcg: value })),
                    (value) => setInfos(prev => ({ ...prev, infoBloodPressure1: value })),
                    (value) => setInfos(prev => ({ ...prev, infoBloodPressure2: value })),
                    (value) => setInfos(prev => ({ ...prev, infoBloodVolume: value })),
                    (value) => setInfos(prev => ({ ...prev, infoBloodOxygenation: value }))
                ).then(({ sciChartSurface, controls }) => {
                    sciChartSurfaces.current[index] = sciChartSurface;
                    controlsRefs.current[index] = controls;
                    controls.handleStart();
                });
            });
        };

        initializeCharts();

        return () => {
            sciChartSurfaces.current.forEach(surface => surface?.delete());
            controlsRefs.current.forEach(controls => controls?.handleStop());
        };
    }, []);

    return (
        <div style={{ display: "flex", height: "100%",height:'50vh' }}>
            {divElementIds.map((divId, index) => (
                <div key={index} id={divId} style={{ flex: 1 }} />
            ))}
            <div style={{ flex: 1, padding: "20px", backgroundColor: "#50C7E022" }}>
                <div>
                    <div className="" style={{ color: "red" }}>ECG</div>
                    <div className="mt-5" style={{ color: "blue" }}>NIBP</div>
                    <div className="mt-5" style={{ color: "green" }}>SV</div>
                    <div className="mt-5" style={{ color: "orange" }}>SPO<span style={{ fontSize: 12 }}>2</span></div>
                </div>
                <div>
                    <div style={{ color: "red" }}>{infos.infoEcg}</div>
                    <div style={{ color: "blue" }}>{infos.infoBloodPressure1}/{infos.infoBloodPressure2}</div>
                    <div style={{ color: "green" }}>{infos.infoBloodVolume.toFixed(1)}</div>
                    <div style={{ color: "orange" }}>{infos.infoBloodOxygenation}</div>
                </div>
            </div>
        </div>
    );
}
