import { useEffect } from 'react'
import { Box } from 'components'
import { useNavigate } from 'react-router-dom'
import { ACTIVE_SESSION_KEY, SESSIONS_KEY } from 'common/constants'
import _omit from 'lodash/omit'
import { useSimulation } from '../SimulationContext'
import { Tasks } from '.'
import './TaskPanel.scss'
import { Accordion } from 'components'
export default function TaskPanel() {
  const { data, setSelectedSession, selectedSession, setIsSessionStarted, completedTasks } = useSimulation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!selectedSession) {
      setSelectedSession(data.tasks[0])
    }
  }, [data, selectedSession, setSelectedSession])

  const onStartingNewSession = () => {
    const session = JSON.parse(localStorage.getItem(SESSIONS_KEY)) || []
    let activeSession = JSON.parse(localStorage.getItem(ACTIVE_SESSION_KEY))
    const activeSessionIndex = session.findIndex((val) => val?.name === activeSession.name)
    activeSession = _omit(activeSession, ['activeTask', 'completedTask'])
    session[activeSessionIndex] = activeSession
    localStorage.setItem(SESSIONS_KEY, JSON.stringify(session))
    localStorage.setItem(ACTIVE_SESSION_KEY, JSON.stringify(activeSession))
    setSelectedSession(data.tasks[0])
    setIsSessionStarted(false)
  }

  return (
    <Box className="task-panel">
      <h3>{data.name}</h3>
      <Tasks />
      <div>
        <button
          onClick={() => navigate('/summary')}
          disabled={
            !completedTasks?.length || selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1
          }>
          session summary
        </button>
        <button
          className="new-simulation-button"
          disabled={
            !completedTasks?.length || selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1
          }
          onClick={onStartingNewSession}>
          new simulation
        </button>
      </div>
    </Box>
  )
}
