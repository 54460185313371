import { useEffect } from 'react'
import checkedIcon from '../../../assets/images/checked.png'
import './Steps.scss'
import { useSimulation } from '../SimulationContext'

export default function Steps({ isFullscreen = false }) {
  const {
    selectedSession,
    setSelectedSession,
    setIsTasksCompleted,
    isTasksCompleted,
    sessionDuration,
    onSessionCompleted,
    setSessionDuration,
    setSceneImage,
    data,
  } = useSimulation()
  const { name, subtasks } = selectedSession

  useEffect(() => {
    if (isTasksCompleted && sessionDuration !== null) {
      const currentOnGoingId = subtasks[subtasks?.findIndex((val) => val?.status === 'ongoing')].id
      const path = `/simulation${data.virtualPath}`
      const updatedSubtask = subtasks.map((val) => {
        if (val?.id === currentOnGoingId) {
          return { ...val, status: 'completed', duration: sessionDuration }
        } else if (val?.id === currentOnGoingId + 1) {
          return { ...val, status: 'ongoing' }
        } else {
          return val
        }
      })
      if (subtasks[subtasks.length - 1].id === currentOnGoingId) {
        onSessionCompleted({ ...selectedSession, subtasks: updatedSubtask })
        setSceneImage(`${path}${data.bright_image}`)
      }
      setSelectedSession({ ...selectedSession, subtasks: updatedSubtask })
      setIsTasksCompleted(false)
    }
  }, [
    isTasksCompleted,
    sessionDuration,
    setIsTasksCompleted,
    subtasks,
    selectedSession,
    onSessionCompleted,
    setSelectedSession,
    data,
    setSceneImage,
  ])

  const onClickTask = (task) => {
    const currentOnGoingTask = subtasks[subtasks?.findIndex((val) => val?.id === task?.id) + 1]
    const path = `/simulation${data.virtualPath}`
    const currentSceneImage = currentOnGoingTask?.image || selectedSession?.image
    if (sessionDuration !== null) {
      setSessionDuration(null)
    }
    if (currentSceneImage) {
      setSceneImage(`${path}${currentSceneImage}`)
    }
    setIsTasksCompleted(true)
  }

  return (
    <div className="steps">
      <menu className={isFullscreen ? 'fullscreen-view' : ''}>
        {subtasks?.map((task) => {
          const ongoing = task.status === 'ongoing'
          return (
            <li key={`${name}${task.id}`} className={task.status === 'completed' ? 'completed' : ''}>
              <button onClick={() => onClickTask(task)} disabled={!ongoing}>
                <div>
                  <img src={checkedIcon} />
                  <div />
                </div>
                <label disabled={!ongoing}>{task.description}</label>
              </button>
            </li>
          )
        })}
      </menu>
    </div>
  )
}
