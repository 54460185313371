import { createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { getSimulation } from './data/queries'
import { ACTIVE_SESSION_KEY, SESSIONS_KEY } from 'common/constants'

const SimulationContext = createContext()

const SimulationProvider = ({ simulationId, children }) => {
  const activeSession = JSON.parse(localStorage.getItem(ACTIVE_SESSION_KEY))
  const completedTasks = activeSession?.completedTask ?? []
  const { isLoading, error, data } = useQuery('lunarsimulation', () => getSimulation(simulationId))
  const [isSessionStarted, setIsSessionStarted] = useState(
    activeSession?.activeTask &&
      activeSession?.activeTask?.subtasks?.findIndex((val) => val?.status === 'completed') !== -1
  )
  const [sessionDuration, setSessionDuration] = useState(null)
  const [isTasksCompleted, setIsTasksCompleted] = useState(false)
  const [selectedSession, setSelectedSession] = useState(activeSession?.activeTask)
  const [sceneImage, setSceneImage] = useState(null)

  const value = {
    isLoading,
    error,
    data,
    isSessionStarted,
    setIsSessionStarted,
    selectedSession,
    setSelectedSession,
    sessionDuration,
    setSessionDuration,
    isTasksCompleted,
    setIsTasksCompleted,
    completedTasks,
    sceneImage,
    setSceneImage,
  }

  return <SimulationContext.Provider value={value}>{children}</SimulationContext.Provider>
}

const useSimulation = () => {

  const context = useContext(SimulationContext)
  if (context === undefined) {
    throw new Error('useSimulation must be used within a SimulationProvider')
  }

  const onSessionCompleted = (sessionDetails) => {
    const activeSession = JSON.parse(localStorage.getItem(ACTIVE_SESSION_KEY))
    const sessions = JSON.parse(localStorage.getItem(SESSIONS_KEY))
    const completedTask = activeSession?.completedTask ?? []
    const activeSessionIndex = sessions?.findIndex((val) => val?.name === activeSession.name)
    completedTask.push(sessionDetails)
    sessions[activeSessionIndex] = { ...activeSession, completedTask, activeTask: sessionDetails }
    localStorage.setItem(
      ACTIVE_SESSION_KEY,
      JSON.stringify({ ...activeSession, completedTask, activeTask: sessionDetails })
    )
    localStorage.setItem(SESSIONS_KEY, JSON.stringify(sessions))
  }

  

  const getInitialOngoingSessionData = (data) => {
    const activeSession = JSON.parse(localStorage.getItem(ACTIVE_SESSION_KEY))
    const session = JSON.parse(localStorage.getItem(SESSIONS_KEY))
    const activeSessionIndex = session?.findIndex((val) => val?.name === activeSession.name)
    const subtasks = data?.subtasks
    session[activeSessionIndex] = { ...activeSession, activeTask: data }
    subtasks[0] = { ...subtasks[0], status: 'ongoing' }
    localStorage.setItem(SESSIONS_KEY, JSON.stringify(session))
    localStorage.setItem(ACTIVE_SESSION_KEY, JSON.stringify({ ...activeSession, activeTask: data }))
    return { ...data, subtasks: subtasks }
  }

  const value = { ...context, onSessionCompleted, getInitialOngoingSessionData }
  return value
}

export { SimulationProvider, useSimulation }
