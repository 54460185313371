import React, { useState, useEffect } from 'react';
import 'assets/css/DialComponent.css';
import Flight_Surgeon from 'assets/dashboard/Flight_Surgeon.png';
import Picture2 from 'assets/dashboard/Picture2.png';
import Researcher from 'assets/dashboard/Researcher.png';
import Astronaut from 'assets/dashboard/Astronaut.png';
import Suit_Engineer from 'assets/dashboard/Suit_Engineer.png';
import Mission_Controller from 'assets/dashboard/Mission_Controller.png';
import logo from "assets/img/Metakosmos_Logomark_CMYK_Black.png";
const DialComponent = () => {
  const [isOpen, setIsOpen] = useState(false); // Set isOpen to false initially
  const images = [
    { src: Flight_Surgeon, role: 'Flight Surgeon' },
    { src: Picture2, role: 'Picture2 Role' }, // Update with correct role name
    { src: Researcher, role: 'Researcher' },
    { src: Astronaut, role: 'Astronaut' },
    { src: Suit_Engineer, role: 'Suit Engineer' },
    { src: Mission_Controller, role: 'Mission Controller' }
  ];
  const userData =  localStorage.getItem('user');

  const userRole = userData ? JSON.parse(userData).user_type : '';
  
 
  useEffect(() => {
    const menu = document.getElementById('menu');
    const items = menu.querySelectorAll('li');

    function circleMenu(options) {
      const settings = Object.assign(
        {
          angleInitial: -90,
          angleIncrement: 60,
          radius: 70,
        },
        options
      );

      let angle = (settings.angleInitial * Math.PI) / 180;
      const angleIncrement = (settings.angleIncrement * Math.PI) / 180;
      const iconCircle = items;

      iconCircle.forEach(function (item, index) {
        if (isOpen) {
          const left = settings.radius * Math.cos(angle);
          const bottom = settings.radius * Math.sin(angle);
          setTimeout(() => {
            item.style.transition = 'all 0.3s ease';
            item.style.left = left + 'px';
            item.style.bottom = bottom + 'px';
          }, index * 100); // Delay each item animation
          angle += angleIncrement;
        } else {
          // Close the menu
          setTimeout(() => {
            item.style.transition = 'all 0.3s ease';
            item.style.left = 0;
            item.style.bottom = 0;
          }, (items.length - index) * 100); // Delay each item closing animation
        }
      });
    }

    circleMenu();

    // Handle the click event for the "+" button
    const icon = menu.querySelector('span a');
    icon.addEventListener('click', function () {
      setIsOpen(!isOpen); // Toggle isOpen state
      const img = this;
      img.classList.toggle('rotate');
    });

  }, [isOpen]); // Listen for changes in isOpen state

  return (
    <div id="wrapper" style={{ backgroundColor: isOpen ? '#9BA4B5' : '' }}>
      <div id="wrapper_content">
        <ul id="menu" className={`circle-menu ${isOpen ? 'open' : ''}`}>
          <span className='text-center'>
            <a className={isOpen ? 'rotate' : ''} >+</a>
          </span>
          {isOpen &&
            images.map((picture, index) => (
              <li key={index} style={{ backgroundColor: picture.role === userRole ? '#01426a' : '' }}>
                <img src={picture.src} alt={picture.role} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default DialComponent;
