/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================


=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import EnvironmentWrapper from "./components/EnvironmentWrapper/EnvironmentWrapper";
import MedicalDataWrapper from "./components/MedicalDataWrapper/MedicalDataWrapper";
import HumanDataWrapper from "components/HumanDataWrapper/HumanDataWrapper";
import FrontHeaderDataWrapper from "components/FrontHeaderDataWrapper/FrontHeaderDataWrapper";
import HumanMetric from "views/HumanMetric";
import MedicalMetric from "views/MedicalMetric";
import EnvironmentMetric from "views/EnvironmentMetric";
import Home from "front/Home";
import DigitalFit from "front/DigitalFit";
import Simulate from "front/Simulation/Simulate";
import SimulateView from "front/Simulation/SimulateView";
import SimulateAnalytics from "front/Simulation/SimulateAnalytics";
import MainDash from "front/MainDash";
import SciChartsExampleOne from "front/SciCharts/SciChartsExampleOne";
import SciChartsExampleTwo from "front/SciCharts/SciChartsExampleTwo";
// import SciChartsExampleThree from "front/SciCharts/SciChartsExampleThree";
import SciChartsExampleFour from "front/SciCharts/SciChartsExampleFour";
import SciChartsExampleFive from "front/SciCharts/SciChartsExampleFive";
import SciChartExample from "front/SciCharts/SciChartExample";
import SciChartExampleThree from "front/SciCharts/SciChartExampleThree";
import Dashboard from "front/Dashboards/Dashboard";
import Landing from'front/Landing/Landing';
import SessionSetup from "front/SessionSetup/SessionSetup";
import SuitLibrary from "front/SuitLibrary/SuitLibrary";
import CreateSuit from "front/CreatSuit/CreateSuit";
import LightningChart from "front/LightningCharts/LightningChart";
import SimulationState from "front/Simulation/SimulationState";
import Kosmosuit from "front/Dashboards/Kosmosuits/Kosmosuit";
import Biomechanics from "front/Dashboards/Kosmosuits/Biomechanics/Biomechanics";
import BioMedical from "front/Dashboards/Kosmosuits/BioMedical/BioMedical";
import SessionSummary from "front/Dashboards/Kosmosuits/SessionSummary/SessionSummary";
import Environment from "front/Dashboards/Kosmosuits/Environment/Environment";
import Cognitive from "front/Dashboards/Kosmosuits/Cognitive/Cognitive";
import FrontFooterDataWrapper from "components/FrontFooterDataWrapper/FrontFooterDataWrapper";
import SuitDiagnostics from "front/Dashboards/Kosmosuits/SuitDiagnostics/SuitDiagnostics";
import VerifyEmail from "front/Landing/VerifyEmail/VerifyEmail";
import ResetPassword from "front/Landing/ResetPassword/ResetPassword";
import UrpsRedirect from "front/UrpsRedirect";
import NodeProjectRedirect from "front/NodeProjectRedirect";
import PythonProjectRedirect from "front/PythonProjectRedirect";
import VideoMotionCapture from "front/Simulation/VideoMotionCapture";
import NewUrpsRedirect from "front/NewUrpsRedirect";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <EnvironmentWrapper>
        <MedicalDataWrapper>
          <HumanDataWrapper>
          <FrontHeaderDataWrapper>
            <FrontFooterDataWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="admin/*" element={<AdminLayout />} />
          <Route path="/rtl/*" element={<RTLLayout />} />
          <Route path="/" element={<Landing />} />
          <Route path="/session" element={<SessionSetup />} />
          <Route path="/suit" element={<SuitLibrary />} />
          <Route path="/creat-suit" element={<CreateSuit />} />
          <Route path="/home" element={<Home />} />
          <Route path="/digital-fit" element={<DigitalFit />} />
          <Route path="/main-dash" element={<MainDash />} />
          <Route path="/simulate" element={<Simulate />} />
          <Route path="/simulate-state" element={<SimulationState />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/kosmosuit" element={<Kosmosuit />} />
          <Route path="/biomedical" element={<BioMedical />} />
          <Route path="/biomechanics" element={<Biomechanics />} />
          <Route path="/environment" element={<Environment />} />
          <Route path="/coginative" element={<Cognitive />} />
          <Route path="/summary" element={<SessionSummary />} />
          <Route path="/suit-diagnostics" element={<SuitDiagnostics />} />
          <Route path="/simulate/view" element={<SimulateView />} />
          <Route path="/simulate/analytics" element={<SimulateAnalytics />} />
          <Route path="scicharts/one" element={<SciChartsExampleOne />} />
          <Route path="scicharts/two" element={<SciChartsExampleTwo />} />
          <Route path="scicharts/three" element={<SciChartExampleThree />} />
          <Route path="scicharts/four" element={<SciChartsExampleFour />} />
          <Route path="scicharts" element={<SciChartExample/>} />
          <Route path="lightning/charts" element={<LightningChart />} />
          <Route path="/urps" element={<UrpsRedirect />} />
          <Route path="/new-urps" element={<NewUrpsRedirect />} />
          <Route path="/node-project" element={<NodeProjectRedirect />} />
          <Route path="/python-project" element={<PythonProjectRedirect />} />
          <Route path="/motioncap" element={<VideoMotionCapture />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset-password/:token" element={<ResetPassword/>} />
      
           {/* <Route path="/admin/*" element={<HumanMetric></HumanMetric>} />
          <Route path="/admin/*" element={<MedicalMetric />} />
          <Route path="/admin/*" element={<EnvironmentMetric/>} /> */}
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </BrowserRouter>
      </FrontFooterDataWrapper>
      </FrontHeaderDataWrapper>
      </HumanDataWrapper>
      </MedicalDataWrapper>
      </EnvironmentWrapper>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);
