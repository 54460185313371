import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'front/Header/Header';
import Footer from 'front/Footer/Footer';
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
} from "reactstrap";

export default function Environment() {
    const location = useLocation();
    // const planets = ['Mercury', 'Venus', 'Earth', 'Mars', 'Saturn','Moon',"Google Earth"];
    const planets = ['Earth', 'Moon', 'Google Earth', 'Asteroids', 'ISS', 'Mars', 'Venus'];

    const [selectedPlanetIndex, setSelectedPlanetIndex] = useState(2);

    const handlePlanetClick = (index) => {
        console.log(index)
        setSelectedPlanetIndex(index);
    };

    return (
        <div>
            <Header currentRoute={location.pathname}></Header>
            <Container fluid>
                <Row>
                    <Col md="12" className='m-2'>
                        <Row className='mr-3 mt-3'>
                            <Col className='' md="8">
                                <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                            {selectedPlanetIndex !== null && (
                                                selectedPlanetIndex === 2 ? (
                                                    <iframe
                                                        src="https://earth.google.com/web/@-4.63132027,-5.62200394,361.00900852a,1219066.73918769d,35y,0h,0t,0r/data=OgMKATA"
                                                        frameborder="0"
                                                        style={{ width: '100%', height: '100vh', border: 'none' }}
                                                        allowfullscreen
                                                    />
                                                ) : selectedPlanetIndex === 4 ? (
                                                    <iframe
                                                        src="https://www.telemetry.space/"
                                                        title="ISS Telemetry"
                                                        style={{ width: '100%', height: '100vh', border: 'none' }}
                                                        className='your-iframe-class'
                                                    />
                                                ) : selectedPlanetIndex === 3 ? (
                                                    <iframe
                                                        src="https://eyes.nasa.gov/apps/asteroids/#/story/asteroids_missions?slide=slide_2"
                                                        title="NASA Asteroids Viewer"
                                                        style={{ width: '100%', height: '100vh', border: 'none' }}
                                                        className='your-iframe-class'
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={`https://eyes.nasa.gov/apps/solar-system/#/${planets[selectedPlanetIndex].toLowerCase()}`}
                                                        title="NASA Solar System Viewer"
                                                        style={{ width: '100%', height: '100vh', border: 'none' }}
                                                        className='your-iframe-class'
                                                    />
                                                )
                                            )}

                                            {
                                                selectedPlanetIndex === null && (
                                                    <div className="text-center mt-5" style={{ height: '50vh' }}>
                                                        <h6>Select a planet to view</h6>
                                                    </div>
                                                )
                                            }
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                            <Col className='' md="4">
                                <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                            <Row className='text-center'>
                                                {planets.map((planet, index) => (
                                                    <Col className={`mt-5 ${selectedPlanetIndex !== null && index !== selectedPlanetIndex ? 'blur' : ''}`} key={index} xs={6} md={12}>
                                                        <Button onClick={() => handlePlanetClick(index)} className={`btn ${index === selectedPlanetIndex ? 'btn-success' : 'btn'}`}
                                                            style={{ background: '#10202b', border: '1px solid #4472c4', borderRadius: '10px' }}>
                                                            {planet}
                                                        </Button>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer></Footer>
        </div>
    )
}
