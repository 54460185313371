import { useSimulation } from '../SimulationContext'
import StretchIcon from '../../../assets/images/stretch.png'
import ShrinkIcon from '../../../assets/images/shrink.png'
import './ScenePreviewHeader.scss'

export default function ScenePreviewHeader({ isFullscreen, setIsFullscreen }) {
  const { isSessionStarted, selectedSession } = useSimulation()

  return (
    <div className="scene-preview-header-container">
      {isFullscreen && <label className="subtask-label">{selectedSession?.name}</label>}
      <label className={isSessionStarted ? 'centered-change-view-label' : ''}>Change View</label>
      {isSessionStarted && (
        <div className={isFullscreen ? 'icon-container' : ''}>
          <img src={isFullscreen ? ShrinkIcon : StretchIcon} onClick={() => setIsFullscreen(!isFullscreen)} />
        </div>
      )}
    </div>
  )
}
