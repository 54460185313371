import './IconButton.css'

const IconButton = ({ icon, children, ...props }) => {
  return (
    <button aria-label="Home" {...props} className={`iconButton ${props.className}`}>
      <img src={icon} />
      {children}
    </button>
  )
}

export default IconButton
