import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import SuitIdImage from 'assets/images/suitId.gif';
function Stopwatch() {
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        setTimer(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (timer) => {
    const getSeconds = `0${(timer % 60)}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  return (
    <div style={{  fontSize: '16px',width:'100%' }}>
      <Row>
        <Col md="6">SUIT ID</Col>
        {/* <Col md="6" style={{ textAlign: 'right' }}>{formatTime(timer)}</Col> */}
        <Col className='text-right' md="6"><img style={{ width:'80px',height:'auto' }} src={SuitIdImage}></img></Col>
      </Row>
      <hr style={{ background: 'lime' }} />
      <Row>
        <Col md="6">SUIT ID</Col>
        {/* <Col md="6" style={{ textAlign: 'right' }}>{formatTime(timer)}</Col> */}
        <Col className='text-right' md="6"><img style={{ width:'80px',height:'auto' }} src={SuitIdImage}></img></Col>
      </Row>
      <hr style={{ background: 'lime' }} />
      <Row>
        <Col md="6">SUIT ID</Col>
        {/* <Col md="6" style={{ textAlign: 'right' }}>{formatTime(timer)}</Col> */}
        <Col className='text-right' md="6"><img style={{ width:'80px',height:'auto' }} src={SuitIdImage}></img></Col>
      </Row>
      <hr style={{ background: 'lime' }} />
    </div>
  );
}

export default Stopwatch;
