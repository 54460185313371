import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { API_BASE_URL } from 'Config';
// import { Button } from 'reactstrap';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    // Example API endpoint
    const apiUrl = `${API_BASE_URL}/forgot-password`;

    try {
      const response = await axios.post(apiUrl, { email });
      console.log(response)
      if (response.status === 200) {
        setSuccessMessage(response.data.message);
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    } catch (error) {
      // console.log(error.response.data.error)
      setErrorMessage(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"  style={{ marginBottom: 15 }}   className={`form-control mb-3 ${ errorMessage ? 'is-invalid' : ''}`}
          />
        </div>
        {successMessage && <p className="text-success">{successMessage}</p>}
        {errorMessage && <span className='text-danger'>{errorMessage}</span>}
        <Button type="submit">
          Send Reset Link
        </Button>
      </form>
     
    </div>
  );
};

export default ForgotPassword;
