import { useRef, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

function BackpackModelView({ src }) {
    const modelRef = useRef();
    const controlsRef = useRef();

    const { scene } = useGLTF(src);

    useEffect(() => {
        if (modelRef.current && scene) {
            console.log(`Updating model for source: ${src}`);

                    modelRef.current.position.y = -1.5;
                    modelRef.current.scale.set(55, 55, 55);
             
    
            console.log(`Position: ${modelRef.current.position.y}, Scale: ${modelRef.current.scale.x}`);
    
            // Force R3F to update the scene
            // This step might not be necessary but is here for demonstration
            modelRef.current.needsUpdate = true;
        }
    }, [scene, src]);

    return (
        <>
            <primitive object={scene} ref={modelRef} />
            <OrbitControls ref={controlsRef} enableZoom={true} />
        </>
    );
}

export default BackpackModelView;