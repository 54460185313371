import React from 'react';
import { useState ,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import TrackingCarousel from './TrackingCarousel';
import { FrontHeaderDataContext } from 'contexts/FrontHeaderDataContext';
import { useLocation } from 'react-router-dom';
import {
    Button,
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";

const buttonStyle = {
    position: 'fixed',
    width: '50px',
    height: '50px',
    background:'#101f2a',
    border:'1px solid #01426A',
    bottom: '40px',
    zIndex: 99,
  };

  
const buttonStyleForData = {
    position: 'fixed',
    width: '50px',
    height: '50px',
    background:'#101f2a',
    border:'1px solid #01426A',
    top: '60vh',
    right: '2vh',
    zIndex: 99,
  };
  
export default function Footer() {
    const location = useLocation();
    const [isScrolledDown, setIsScrolledDown] = useState(false);
    const [changeDashboard,setDashboard] = useState(false);
    const { stageFlight, suitMode, activity, activitiesByFlightStage, changeStageFlight, changeSuitMode, changeActivity,changeDashboardView,handleDashboards } = useContext(FrontHeaderDataContext);

    const navigate = useNavigate();

    const handleDashboardsChange = () => {
        handleDashboards(!changeDashboard);
        // setDashboard(!changeDashboard);
    };
const goBack = () => {
    navigate(-1); // Go back one step in history
};

const handleScroll = () => {
    const currentPosition = window.pageYOffset;
    const scrollAmount = 450; // Adjust this value to change the scroll amount

    if (!isScrolledDown) {
        window.scrollTo({
            top: currentPosition + scrollAmount,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo({
            top: currentPosition - scrollAmount,
            behavior: 'smooth'
        });
    }

    setIsScrolledDown(!isScrolledDown);
};
    return (
        <Container fluid>
            {location.pathname === '/dashboard' && (
                <Row>
                    <Col md="12">
                        <button onClick={handleDashboardsChange} style={buttonStyleForData} className="rounded-circle pull-right">
                            <i className={`fa fa-angle-${changeDashboardView ? 'left' : 'right'} text-center text-white`} />
                        </button>
                    </Col>
                </Row>
            )}
            <Row className='text-center'>
                <Col md="12">
                <button onClick={handleScroll} style={buttonStyle} className="rounded-circle back-to-top">
                        <i className={`fa fa-angle-${isScrolledDown ? 'up' : 'down'} text-center text-white`} />
                    </button>
                </Col>
            </Row>
            <Row className='bg-dark mt-5' style={{ background: '#14181B' }}>
                <Col md="2">
                    <Button className='' onClick={goBack} style={{ background: '#101f2a', border: '1px solid #01426A', paddingTop: '10px', paddingBottom: '10px' }}>Back</Button>
                </Col>
                <Col className='' md="10">
                    {/* <Card className='main-dashboard' > */}
                            {/* <CardBody> */}
                                <TrackingCarousel />
                            {/* </CardBody> */}
                    {/* </Card> */}
                </Col>
            </Row>
        </Container>
    )
}
