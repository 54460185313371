import { useState, useEffect, useContext } from 'react'
import { Box } from 'components'
import { baseurl } from 'common/config'
import { useSimulation } from '../SimulationContext'
import Loading from '../../../assets/images/loading.gif'
import useSceneLoader from './hooks/useSceneLoader'
import { Steps, DurationClock, ScenePreviewHeader, Metric, TaskPanel } from '.'
import './ScenePreview.scss'
import { FrontHeaderDataContext } from 'contexts/FrontHeaderDataContext';
const metricData = [
  {
    value: 2,
    unit: 'hours',
    label: 'battery life',
  },
  {
    value: 1.42,
    unit: 'm/s2',
    label: 'gravity',
  },
]

const ScenePreview = () => {
  const {isisActive,setIsIsActive} = useContext(FrontHeaderDataContext);
  const {
    data,
    isSessionStarted,
    selectedSession,
    setIsSessionStarted,
    setSelectedSession,
    getInitialOngoingSessionData,
    completedTasks,
    sceneImage,
    setSceneImage,
  } = useSimulation()
  const [isFullscreen, setIsFullscreen] = useState(false)
  const { isSceneLoaded, sceneUrl, setIsSceneLoaded } = useSceneLoader(baseurl, sceneImage)
  useEffect(() => {
    if (!sceneImage) {
      setSceneImage(`/simulation${data?.virtualPath}${data?.bright_image}`)
    }
  }, [data, setSceneImage, sceneImage])

  useEffect(() => {
    setIsSceneLoaded(false)
  }, [setIsSceneLoaded, sceneImage])

  const onTaskStarted = () => {
    const path = `/simulation${data.virtualPath}`
    const currentSceneImage = selectedSession.subtasks[0]?.image || selectedSession?.image
    setSelectedSession(getInitialOngoingSessionData(selectedSession))
    if (currentSceneImage) {
      setSceneImage(`${path}${currentSceneImage}`)
    }
    setIsSessionStarted(true)
  }

  return (
    <Box className="scene-preview-panel">
      
      <>
            {isisActive ? (
                <div>
                    <iframe
                        src="https://www.telemetry.space/"
                        title="NASA Solar System Viewer"
                        style={{ width: '100%', height: '100vh', border: 'none' }}
                        className='your-iframe-class'
                    />
                </div>
            ) : (
                <div
                    style={{
                        backgroundImage: isSceneLoaded ? `url(${sceneUrl})` : `url(${Loading})`,
                        backgroundRepeat: !isSceneLoaded && 'no-repeat',
                        backgroundSize: !isSceneLoaded && '50px 50px',
                    }}
                    className={`${isSessionStarted ? '' : 'bg-overlay'} ${isFullscreen && isSessionStarted ? 'fullScreen' : ''}`}
                >
                    <ScenePreviewHeader isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
                    {isFullscreen &&
                        isSessionStarted &&
                        selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1 && (
                            <Steps isFullscreen={isFullscreen} />
                        )}
                    {isFullscreen &&
                        isSessionStarted &&
                        selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') === -1 && (
                            <div className="Tasks-list">
                                <TaskPanel />
                            </div>
                        )}
                    {!isSessionStarted && (
                        <div className="select-button-container">
                            <div>
                                <button
                                    onClick={onTaskStarted}
                                    disabled={!selectedSession?.name || data.tasks.length === completedTasks?.length}
                                >
                                    select task
                                </button>
                            </div>
                        </div>
                    )}
                    {isSessionStarted && selectedSession?.subtasks?.findIndex((val) => val?.status === 'ongoing') !== -1 && (
                        <div className={isFullscreen ? 'panel-footer' : ''}>
                            {isSessionStarted && <DurationClock isFullscreen={isFullscreen} />}
                            {isFullscreen &&
                                metricData.map((data) => (
                                    <Metric key={data.label} value={data.value} unit={data.unit} label={data.label} />
                                ))}
                        </div>
                    )}
                </div>
            )}
        </>
    </Box>
  )
}

export default ScenePreview
