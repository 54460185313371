import { useState } from 'react'
import Arrow from '../../assets/images/arrow.svg'
import './Accordion.scss'

const Accordion = ({ title, children, showIndicator, click, icon, itemClassName = '', sectionClassName = '' }) => {
  const [isActive, setIsActive] = useState(false)

  const closeAccordion = () => {
    setIsActive(!isActive)
    if (click) {
      click()
    }
  }

  return (
    <div className="accordion-wrapper">
      <div className={`accordion-item ${itemClassName}`}>
        <div className={`accordion-section ${sectionClassName}`} onClick={() => closeAccordion()}>
          <label>{title}</label>
          <div>
            {showIndicator ? <div /> : null}
            {icon ? <img src={icon} className={'icon'} /> : null}
            {isActive ? <img className="rotated" src={Arrow} /> : <img src={Arrow} />}
          </div>
        </div>
        {isActive && <div>{children}</div>}
      </div>
    </div>
  )
}

export default Accordion
