import './ImageTile.scss'

const ImageTile = ({ metric, XAxisMetric, YAxisMetric }) => {
  return (
    <div className="metricImgContainer">
      <div>
        <label>{XAxisMetric}</label>
        <img src={metric.image} />
      </div>
      <label>{YAxisMetric}</label>
    </div>
  )
}

export default ImageTile
