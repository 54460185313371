import React, { useEffect } from 'react';
import 'assets/css/dashboard.css';
import { SciChartSurface, NumericAxis, SciChartJsNavyTheme, EAxisAlignment, LeftAlignedOuterVerticallyStackedAxisLayoutStrategy, FastLineRenderableSeries, RolloverModifier, YAxisDragModifier, XyDataSeries } from 'scichart'; // Import your SciChart library

const SciChartsExampleOne = () => {
  useEffect(() => {
    const initSciChart = async () => {
      const {
        wasmContext,
        sciChartSurface
      } = await SciChartSurface.create("scichart-one", {
        theme: new SciChartJsNavyTheme()
      });

      sciChartSurface.layoutManager.leftOuterAxesLayoutStrategy = new LeftAlignedOuterVerticallyStackedAxisLayoutStrategy();

      sciChartSurface.xAxes.add(new NumericAxis(wasmContext, {
        axisTitle: "X Axis",
        axisTitleStyle: { fontSize: 13 },
        backgroundColor: "#50C7E022",
        axisBorder: { color: "#50C7E0", borderTop: 1 }
      }));

      sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { id: "YAxis0", axisTitle: "Y Axis 0", axisAlignment: EAxisAlignment.Left }));
      sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { id: "YAxis1", axisTitle: "Y Axis 1", axisAlignment: EAxisAlignment.Left }));
      sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { id: "YAxis2", axisTitle: "Y Axis 2", axisAlignment: EAxisAlignment.Left }));
      sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { id: "YAxis3", axisTitle: "Y Axis 3", axisAlignment: EAxisAlignment.Left }));
      sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { id: "YAxis4", axisTitle: "Y Axis 4", axisAlignment: EAxisAlignment.Left }));

      const axisColors = ["#50C7E0", "#EC0F6C", "#30BC9A", "#F48420", "#364BA0", "#882B91", "#67BDAF", "#C52E60"];
      sciChartSurface.yAxes.asArray().forEach((yAxis, index) => {
        yAxis.backgroundColor = axisColors[index] + "22";
        yAxis.axisBorder = { color: axisColors[index], borderRight: 1 };
        yAxis.axisTitleStyle.fontSize = 13;
      });

      const getOptions = (index) => {
        const xValues = Array.from(Array(50).keys());
        const yValues = xValues.map(x => Math.sin(x * 0.4 + index));

        return {
          yAxisId: `YAxis${index}`,
          stroke: axisColors[index],
          strokeThickness: 2,
          dataSeries: new XyDataSeries(wasmContext, { xValues, yValues })
        };
      };

      sciChartSurface.chartModifiers.add(new RolloverModifier(), new YAxisDragModifier());

      sciChartSurface.renderableSeries.add(new FastLineRenderableSeries(wasmContext, { ...getOptions(0) }));
      sciChartSurface.renderableSeries.add(new FastLineRenderableSeries(wasmContext, { ...getOptions(1) }));
      sciChartSurface.renderableSeries.add(new FastLineRenderableSeries(wasmContext, { ...getOptions(2) }));
      sciChartSurface.renderableSeries.add(new FastLineRenderableSeries(wasmContext, { ...getOptions(3) }));
      sciChartSurface.renderableSeries.add(new FastLineRenderableSeries(wasmContext, { ...getOptions(4) }));
    };

    initSciChart();
  }, []);

  return <div id="scichart-one" 
              style={{
                width: '100%', 
                minHeight: '25vh', // Ensures the chart has a minimum height of 50% of the viewport height
                height: 'auto' // Optional: allows the container to grow taller than the minHeight if content requires
              }}
           />;
};

export default SciChartsExampleOne;
