import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // To get the token from the URL
import "./ResetPassword.css"
import { API_BASE_URL } from 'Config';

const ResetPassword = () => {
  const { token } = useParams(); // Extract token from the URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const navigate = useNavigate()

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/reset-password`, {
        token,
        newPassword,
        confirmPassword,
      });
      setSuccessMessage(response.data.message);

      if(response.data.message){
        setTimeout(() => {
            navigate('/')
        }, 1000);
      }

      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.error );
      setSuccessMessage('');
    }
  };

  return (
    <div className="reset-password-container">
      <h6>Reset Your Password</h6>
      {successMessage && <p className='text-success'>{successMessage}</p>}
      {errorMessage && <p className="text-dander">{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <input
         type="password"
          name="newPassword"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required className={`form-control mb-3 ${ errorMessage ? 'is-invalid' : ''}`}
        />
        
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required  className={`form-control mb-3 ${ errorMessage ? 'is-invalid' : ''}`}
        />
        
        <button type="submit ">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
