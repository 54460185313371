import React, { useState ,useContext} from 'react';
import { useLocation } from 'react-router-dom';
import 'assets/css/dashboard.css';
import MainDashboard from './MainDashboard';
import SecondDashboard from './SecondDashboard';
import FrontNavbar from 'front/FrontNavbar';
import { Container } from 'reactstrap';
import Header from 'front/Header/Header';
import Kosmosuit from './Kosmosuits/Kosmosuit';
import Footer from 'front/Footer/Footer';
import { FrontHeaderDataContext } from 'contexts/FrontHeaderDataContext';

  
  const Dashboard = () => {
    const location = useLocation();
    // const [isLightMode, setIsLightMode] = useState(false);
    const { stageFlight, suitMode, activity, activitiesByFlightStage, changeStageFlight, changeSuitMode, changeDashboardView,change,isLightMode,handleIsLightMode } = useContext(FrontHeaderDataContext);
    console.log(changeDashboardView);
    const handleToggle = () => {
      // setIsLightMode((prevMode) => !prevMode);
      handleIsLightMode(!isLightMode);
    };
  
    return (
      <div>
        <Header  currentRoute={location.pathname}></Header>
      <div className={`dashboard-body ${isLightMode ? 'light' : ''}`}>
        <div className="toggle-btn" onClick={handleToggle}>
          <div className={`tdnn ${isLightMode ? 'day' : ''}`}>
            <div className={`moon ${isLightMode ? 'sun' : ''}`}></div>
          </div>
        </div>
        {/* {isLightMode ? <SecondDashboard /> : <MainDashboard />} */}
        {/* {changeDashboardView ? <MainDashboard /> : <SecondDashboard />} */}
        {isLightMode ? <Kosmosuit /> : (changeDashboardView ? <MainDashboard /> : <SecondDashboard />)}


      </div>
      <Footer></Footer>
      </div>
    );
  };
  
  export default Dashboard;
