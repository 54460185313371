import React from 'react';
import { useState, useEffect, useContext } from 'react';
import HumanDataContext from "contexts/HumanDataContext";
import RunMotionGif from "assets/dashboard/Motion-Gifs/run-4fps-V1.gif";
import KneelMotionGif from "assets/dashboard/Motion-Gifs/kneel-4fps-V1.gif";
import TorsoMotionGif from "assets/dashboard/Motion-Gifs/torso-4fps-V1.gif";
import SuitConfigurationIcon from "assets/dashboard/suit_configuration.gif"
import BiomechanicsIcon from "assets/dashboard/biomechanics.gif";
import CognitiveIcon from "assets/dashboard/cognitive.gif";
import SuitDiagnosticsIcon from 'assets/img/SuitConfigurationIcon.gif';
import BiomedicalIcon from 'assets/img/BiomedicalIcon.gif';
import EnvironmentIcon from 'assets/img/EnvironmentIcon.gif';
import { TransPerancyBg } from 'assets/dashboard/Transperant_Bg.png';
import Cameraviewgif from 'assets/images/camera_view.gif';
import FleetViewImage from 'assets/images/fleet_view.png';
import MapPositioningImage from 'assets/dashboard/Motion-Gifs/walk-4fps-V1.gif';
import CautionWarningSystemImage from 'assets/images/caution_warning_system.gif';
import ProcedureViewImage from 'assets/images/ProcedureView.png';
import TwoWayAudioImage from 'assets/images/TwoWayAudio.gif';
import MoonImage from 'assets/images/moonImage.jpg';
// import tilesData from '../Dashboards/data/Tilesdata.json';

import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table,
    ModalBody
} from "reactstrap";
import DialComponent from './DialComponent';
import { Link } from 'react-router-dom';

function MainDashboard(props) {

    const messages = [
        { sender: 'MCR', content: 'How do you feel' },
        { sender: 'Suit ID', content: 'I am OK' },
        { sender: 'MCR', content: 'Have you spotted any aliens yet ??' },
        { sender: '', content: 'HELP HELP' },
    ];

    const [timeCode, setTimeCode] = useState({ hours: 0, minutes: 0, seconds: 0, frames: 0 });
    const { data } = useContext(HumanDataContext);
    const [testimonialIndex, setTestimonialIndex] = useState(0);
    const metricsDelay = 5000; // Change delay as needed
    const metricsInterval = 1; // Change number of items to show at a time

    useEffect(() => {
        const interval = setInterval(() => {
            setTestimonialIndex((prevIndex) => (prevIndex + 1) % data.metrics.length);
        }, metricsDelay);

        return () => {
            clearInterval(interval);
        };
    }, [testimonialIndex, data.metrics.length]);

    const handleBadgeClick = (index) => {
        setTestimonialIndex(index);
        clearInterval(metricsInterval);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeCode((prevTimeCode) => {
                let { hours, minutes, seconds, frames } = prevTimeCode;
                frames++;
                if (frames >= 25) {
                    frames = 0;
                    seconds++;
                    if (seconds >= 60) {
                        seconds = 0;
                        minutes++;
                        if (minutes >= 60) {
                            minutes = 0;
                            hours++;
                        }
                    }
                }
                return { hours, minutes, seconds, frames };
            });
        }, 40); // 1000 ms / 25 fps = 40 ms per frame
        return () => clearInterval(interval);
    }, []);

    const formatTime = (unit) => unit.toString().padStart(2, '0');

    return (
        <div
            // style={{ backgroundColor: '#191f24' }}
            style={{ backgroundImage: `url(${MoonImage})` }}
        >
            <Container fluid>
                <Row>
                    <Col md="12" className='mr-3 mt-2'>
                        <Row className='mr-3 mt-3'>
                            <Col className='' md="4">
                                <Card className='main-dashboard'
                                        style={{
                                            backgroundImage: `url(${Cameraviewgif})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover', // Ensures the image fits within the container without losing its aspect ratio
                                            backgroundPosition: 'center', // Centers the image within the container
                                            width: '100%', // Set width as needed
                                            // height: '100%', // Set height as needed
                                        }}
                                >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                            <h6 className='text-center'>CAMERA VIEW</h6>
                                            {/* <img src={RunMotionGif} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                            <Col className='' md="4">
                                <Card className='main-dashboard' 
                                    //   style={{ background: '#14181B' }}
                                    style={{
                                        backgroundImage: `url(${FleetViewImage})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover', // Ensures the image fits within the container without losing its aspect ratio
                                        backgroundPosition: 'center', // Centers the image within the container
                                        width: '100%', // Set width as needed
                                        // height: '100%', // Set height as needed
                                       }}
                                      >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody 
                                        //  style={{ background: '#14181B' }}
                                         >
                                            <h6 className='text-center'>Fleet View</h6>
                                            {/* <img src={KneelMotionGif} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                            <Col className='' md="4">
                                <Card className='main-dashboard' 
                                    //   style={{ background: '#14181B' }}
                                    style={{
                                        backgroundImage: `url(${TorsoMotionGif})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover', // Ensures the image fits within the container without losing its aspect ratio
                                        backgroundPosition: 'center', // Centers the image within the container
                                        width: '100%', // Set width as needed
                                        // height: '100%', // Set height as needed
                                       }}
                                      >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody 
                                            // style={{ background: '#14181B' }}
                                            >
                                            <h6 className='text-center'>ASTRONAUT POSITION SYSTEM (APS)</h6>
                                            {/* <img src={TorsoMotionGif} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                   
                    <Col md="12">
                        <Row className='m-2 rounded'
                            style={{ backgroundColor: 'rgba(122,120,117,0.7)', }}

                        >
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/summary">
                                        <img src={SuitConfigurationIcon}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>SUIT CONFIGURATION</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/suit-diagnostics">
                                        <img src={SuitDiagnosticsIcon} style={{ width: '40%', height: 'auto' }}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>SUIT DIAGNOSTICS</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/biomedical">
                                        <img src={BiomedicalIcon} style={{ width: '40%', height: 'auto' }}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>BIOMEDICAL</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/biomechanics">
                                        <img src={BiomechanicsIcon}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>BIOMECHANICS</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/coginative">
                                        <img src={CognitiveIcon}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>COGNATIVE</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/environment">
                                        <img src={EnvironmentIcon} style={{ width: '40%', height: 'auto' }} ></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>ENVIRONMENT</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <DialComponent></DialComponent>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default MainDashboard;