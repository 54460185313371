import React, { useState, useEffect, useContext } from 'react'
import logo from "assets/img/Kosmosuit_logo.png";
import '../assets/css/home.scss';
import '../assets/css/home.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FrontFooterDataContext } from 'contexts/FrontFooterDataContext';
import {
  Button,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardTitle,
  Table
} from "reactstrap";
import Header from './Header/Header';

function Home(props) {
  const location = useLocation();
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const { userNavigationTrackingState, userNavigationTracking } = useContext(FrontFooterDataContext);

  const handleLogers = (jsonData) => {

    userNavigationTracking(jsonData)
  }

  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  return (
    <div >
      {/* <Container className='m-5'> */}
      {/* <div className="content">
          <Row className='m-5'>
            <Col md="4">
              <Card className='homepage' style={{ background: '#14181B' }}>
                <div className="inner_homepage">
                  <span className="corner-top-left"></span>
                  <span className="corner-top-right"></span>
                  <CardBody>
                    <Row>
                      <Col className='text-center' md="12">
                        <span>Suit Library</span>
                      </Col>
                    </Row>
                  </CardBody>
                  <span className="corner-bottom-left"></span>
                  <span className="corner-bottom-right"></span>
                </div>
              </Card>
            </Col>
            <Col md="4">
              <Card className='homepage' style={{ background: '#14181B' }}>
                <div className="inner_homepage">
                  <span className="corner-top-left"></span>
                  <span className="corner-top-right"></span>
                  <CardBody>
                    <Row>
                      <Col className='text-center' md="12">
                      <span>Create Suit</span>
                      </Col>
                    </Row>
                  </CardBody>
                  <span className="corner-bottom-left"></span>
                  <span className="corner-bottom-right"></span>
                </div>
              </Card>
            </Col>
            <Col md="4">
            <Link to="/digital-fit">
              <Card className='homepage' style={{ background: '#14181B' }}>
                <div className="inner_homepage">
                  <span className="corner-top-left"></span>
                  <span className="corner-top-right"></span>
                  <CardBody>
                    <Row>
                      <Col className='text-center' md="12">
                        <span>Edit Digital Fit</span>
                      </Col>
                    </Row>
                  </CardBody>
                  <span className="corner-bottom-left"></span>
                  <span className="corner-bottom-right"></span>
                </div>
              </Card>
              </Link>
            </Col>
            <Col md="4">
            <Link to="/simulate">
              <Card className='homepage' style={{ background: '#14181B' }}>
                <div className="inner_homepage">
                  <span className="corner-top-left"></span>
                  <span className="corner-top-right"></span>
                  <CardBody>
                    <Row>
                      <Col className='text-center' md="12">
                        <span>Simulate</span>
                      </Col>
                    </Row>
                  </CardBody>
                  <span className="corner-bottom-left"></span>
                  <span className="corner-bottom-right"></span>
                </div>
              </Card>
              </Link>
            </Col>
            <Col md="4">
            <Link to="/admin/dashboard">
              <Card className='homepage' style={{ background: '#14181B' }}>
                <div className="inner_homepage">
                  <span className="corner-top-left"></span>
                  <span className="corner-top-right"></span>
                  <CardBody>
                    <Row>
                      <Col className='text-center' md="12">
                        <span>Data Manager</span>
                      </Col>
                    </Row>
                  </CardBody>
                  <span className="corner-bottom-left"></span>
                  <span className="corner-bottom-right"></span>
                </div>
              </Card>
              </Link>
            </Col>
            <Col md="4">
             <Link to="/dashboard">
             <Card className='homepage' style={{ background: '#14181B' }}>
                <div className="inner_homepage">
                  <span className="corner-top-left"></span>
                  <span className="corner-top-right"></span>
                  <CardBody>
                    <Row>
                      <Col className='text-center' md="12">
                        <span>Dashboard</span>
                      </Col>
                    </Row>
                  </CardBody>
                  <span className="corner-bottom-left"></span>
                  <span className="corner-bottom-right"></span>
                </div>
              </Card>
             </Link>
            </Col>
          </Row>
        </div> */}
      {/* </Container> */}
       <Header  currentRoute={location.pathname}></Header>
      <main class="content">
        <div class="dashboard-container">
        <Link to="/suit">
          <div class="card-content content-container">
            <button className='text-white' 
                    onClick={() => {handleLogers({url: '/suit',name: 'Suit Library'})}}
              >Suit Library</button>
          </div>
          </Link>
          <Link to="/creat-suit">
          <div class="card-content content-container">
            <button className='text-white' 
                    onClick={() => {handleLogers({url: '/creat-suit',name: 'Create suit'})}}
            >Create suit</button>
          </div>
          </Link>
          <Link to="/digital-fit">
          <div class="card-content content-container">
            <button className='text-white' 
                    onClick={() => {handleLogers({url: '/creat-suit',name: 'Edit Digital Fit'})}}
              >Edit Digital Fit</button>
          </div>
          </Link>
          <Link to="/simulate">
          <div class="card-content content-container">
            <button className='text-white'
                    onClick={() => {handleLogers({url: '/simulate',name: 'Simulate'})}}
              >Simulate</button>
          </div>
          </Link>
          <Link to="/admin/dashboard">
          <div class="card-content content-container">
            <button className='text-white'
                    onClick={() => {handleLogers({url: '/admin/dashboard',name: 'Data Manager'})}}
              > Data Manager</button>
          </div>
          </Link>
          <Link to="/dashboard">
          <div class="card-content content-container">
            <button className='text-white' 
                    onClick={() => {handleLogers({url: '/dashboard',name: 'Dashboards'})}}
              >Dashboards</button>
          </div>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Home;