import './Box.css'

const Box = (props) => {
  return (
    <div {...props} className={`${props.className} border`}>
      {props.children}
    </div>
  )
}

export default Box
