import React from 'react';
import { useState, useRef, useEffect } from 'react';
import 'assets/css/createsuit.scss';
import 'assets/css/box.css';
import CosmosWaveImage from 'assets/images/cosmos_wave_background.png'
import { useLocation } from 'react-router-dom';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useGLTF } from '@react-three/drei';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

import HelmetModelView from './3DModels/HelmetModelView';
import GloveModelView from './3DModels/GloveModelView';
import ChestModelView from './3DModels/ChestModelView';
import BootsModelView from './3DModels/BootsModelView';
import BackpackModelView from './3DModels/BackpackModelView';
import Header from 'front/Header/Header';
import { Canvas } from 'react-three-fiber';
import ModelViewer from './ModelViewer';
import Footer from 'front/Footer/Footer';
function CreateSuit(props) {
    const location = useLocation();
    const [activeModel, setActiveModel] = useState('/helmetv4.glb');
    const [suitSelectedItem, setSuitSelectedItem] = useState(null);
    const [componetSelectedItem, setComponetSelectedItem] = useState('helmet');
    const [activeImage, setImage] = useState('https://bioastroause.blob.core.windows.net/suit/components/helmet/helmet_0001.png');

    const renderModelComponent = () => {
        console.log(componetSelectedItem);
        switch (componetSelectedItem) {
            case 'helmet':
                return <HelmetModelView src={activeModel} />;
            case 'glove':
                return <GloveModelView src={activeModel} />;
            case 'Torso':
                return <ChestModelView src={activeModel} />;
            case 'boots':
                return <BootsModelView src={activeModel} />;
            case 'Life support ':
                return <BackpackModelView src={activeModel} />;
            // Add cases for other component selected items...
            default:
                return null; // Or a default component
        }
    };
    const handleSuitItemClick = (item) => {
        setSuitSelectedItem(item);
    };

    const handleComponetSelectedItemClick = (item) => {
        setComponetSelectedItem(item);
        switch (item) {
            case 'helmet':
                setActiveModel('/helmetv4.glb');
                setImage('https://bioastroause.blob.core.windows.net/suit/components/helmet/helmet_0001.png');
                break;
            case 'glove':
                setActiveModel('/Right-GloveV4.glb')
                setImage('https://bioastroause.blob.core.windows.net/suit/components/glove/glove_0001.png');
                break;
            case 'Torso':
                setActiveModel('/upper body1.glb')
                setImage('https://bioastroause.blob.core.windows.net/suit/components/chest/chest_0001.png');
                break;
            case 'boots':
                setActiveModel('/Right leg1.glb')
                setImage('https://bioastroause.blob.core.windows.net/suit/components/boots/boots_0001.png');
                break;
            case 'Life support ':
                setActiveModel('/upper body1.glb')
                setImage('https://bioastroause.blob.core.windows.net/suit/components/backpack/backpack_0001.png');
                break;

            default:
                break;
        }
    }

    function ActiveModelViewer({ src }) {
        const activeModelRef = useRef();
        const currentControlsRef = useRef();
        // Use destructuring to get the scene from useGLTF
        const { nodes, materials } = useGLTF(src);

        useEffect(() => {
            if (activeModelRef.current && nodes) {
                // Ensure activeModelRef.current.scale is defined before accessing its methods
                if (activeModelRef.current.scale && typeof activeModelRef.current.scale.set === 'function') {
                    activeModelRef.current.position.y = -12.5;
                    activeModelRef.current.scale.set(55, 55, 55);
                } else {
                    // Handle the case where activeModelRef.current.scale is undefined or set method is not a function
                    console.error("Scale is undefined or set method is not a function in activeModelRef.current");
                }
            }
        }, [nodes, activeModel]);

        return (
            <>
                {/* Pass the nodes to the primitive */}
                <primitive object={nodes} ref={activeModelRef} />
                <OrbitControls ref={currentControlsRef} enableZoom={true} />
            </>
        );
    }

    return (
        <div>
            <Header currentRoute={location.pathname}></Header>
            <main className="content">
                <div className="create-suit-container">
                    <div className="list-panel border">
                        <label className="title">Select Suit</label>
                        <menu className="list-container scrollbar">
                            <li>
                                <button className={`item-container ${suitSelectedItem === 'Terra' ? 'selected-item' : 'disabled-item-style'}`} onClick={() => handleSuitItemClick('Terra')}>
                                    <img src="https://bioastroause.blob.core.windows.net/suit/terra/terra_(3x2).png" className="list-item-img" />
                                    <label className="card-label">Terra</label>
                                </button>
                            </li>
                            <li>
                                <button className={`item-container ${suitSelectedItem === 'Aqua' ? 'selected-item' : ''}`} onClick={() => handleSuitItemClick('Aqua')}>
                                    <img src="https://bioastroause.blob.core.windows.net/suit/aqua/aqua_(3x2).png" className="list-item-img" />
                                    <label className="card-label">Aqua</label>
                                </button>
                            </li>
                            <li>
                                <button className={`item-container ${suitSelectedItem === 'Vyom' ? 'selected-item' : 'disabled-item-style'}`} onClick={() => handleSuitItemClick('Vyom')}>
                                    <img src="https://bioastroause.blob.core.windows.net/suit/vyom/vyom_(3x2).png" className="list-item-img" />
                                    <label className="card-label">Vyom</label>
                                </button>
                            </li>
                        </menu>
                    </div>
                    <div className="list-panel border">
                        <label className="title">Components</label>
                        <menu className="list-container scrollbar">
                            <li>
                                <button className={`item-container ${componetSelectedItem === 'helmet' ? 'selected-item' : ''}`}>
                                    <img src="https://bioastroause.blob.core.windows.net/suit/components/helmet/helmet_0001.png" className={`${componetSelectedItem === 'helmet' ? 'list-item-img list-image' : 'list-item-img list-image disabled-item-style'}`} onClick={() => handleComponetSelectedItemClick('helmet')} />
                                    {/* <Canvas className='model list-item-img list-image' onClick={() => handleComponetSelectedItemClick('helmet')}>
                                                <ambientLight />
                                                <ModelViewer src={'/head1.glb'} />
                                                </Canvas> */}
                                </button>
                            </li>
                            <li>
                                <button className={`item-container ${componetSelectedItem === 'glove' ? 'selected-item' : ''}`}>
                                    <img src="https://bioastroause.blob.core.windows.net/suit/components/glove/glove_0001.png" className={`${componetSelectedItem === 'glove' ? 'list-item-img list-image' : 'list-item-img list-image disabled-item-style'}`} onClick={() => handleComponetSelectedItemClick('glove')} />
                                    {/* <Canvas className='model list-item-img list-image' onClick={() => handleComponetSelectedItemClick('glove')}>
                                                <ambientLight />
                                                <ModelViewer src={'/left arm1.glb'} />
                                                </Canvas> */}
                                </button>
                            </li>
                            <li>
                                <button className={`item-container ${componetSelectedItem === 'Torso' ? 'selected-item' : ''}`}>
                                    <img src="https://bioastroause.blob.core.windows.net/suit/components/chest/chest_0001.png" className={`${componetSelectedItem === 'Torso' ? 'list-item-img list-image' : 'list-item-img list-image disabled-item-style'}`} onClick={() => handleComponetSelectedItemClick('Torso')} />
                                    {/* <Canvas className='model list-item-img list-image' onClick={() => handleComponetSelectedItemClick('chest')}>
                                                <ambientLight />
                                                <ModelViewer src={'/upper body1.glb'} />
                                                </Canvas> */}
                                </button>
                            </li>
                            <li>
                                <button className={`item-container ${componetSelectedItem === 'Life support ' ? 'selected-item' : ''}`}>
                                    <img src="https://bioastroause.blob.core.windows.net/suit/components/backpack/backpack_0001.png" className={`${componetSelectedItem === 'Life support ' ? 'list-item-img list-image' : 'list-item-img list-image disabled-item-style'}`} onClick={() => handleComponetSelectedItemClick('Life support ')} />
                                    {/* <Canvas className='model list-item-img list-image disabled-item-style' onClick={() => handleComponetSelectedItemClick('backpack')}>
                                                <ambientLight />
                                                <ModelViewer src={'/Right leg1.glb'} />
                                                </Canvas> */}

                                </button>
                            </li>
                            <li>
                                <button className={`item-container ${componetSelectedItem === 'boots' ? 'selected-item' : ''}`}>
                                    <img src="https://bioastroause.blob.core.windows.net/suit/components/boots/boots_0001.png" className={`${componetSelectedItem === 'boots' ? 'list-item-img list-image' : 'list-item-img list-image disabled-item-style'}`} onClick={() => handleComponetSelectedItemClick('boots')} />
                                    {/* <Canvas className='model className="list-item-img list-image disabled-item-styl' onClick={() => handleComponetSelectedItemClick('boots')}>
                                                <ambientLight />
                                                <ModelViewer src={'/right knee leg1.glb'} />
                                                </Canvas> */}
                                </button>
                            </li>
                        </menu>
                    </div>
                    <div className="properties-panel border">
                        <label className="font-properties title">Properties</label>
                        <div className="scrollbar">
                            {/* <label className="font-properties description">Onboard Computing</label> */}
                            {/* <label className="font-properties description">Pressure &amp; Gas Control System</label> */}
                            <label className="font-properties description">Digital Control Systems</label>
                            <label className="font-properties description">O2/CO2 Pipes</label>
                        </div>
                    </div>
                    <div className="component-previewer-options-btn-container">
                        <div className="component-preview-panel border" style={{ backgroundImage: `url(${CosmosWaveImage})` }}>
                            <div className="preview-card-header">
                                <label className="title">{componetSelectedItem}</label>
                                <button className="component-configure-button">detach</button>
                            </div>
                            <div className="carousel-view">
                                <div className="left-arrow">
                                    {/* <img src="/static/media/right-arrow.8e05d407d31867d4acc0d73839982e33.svg" /> */}
                                </div>
                                <div className="slider">
                                    <div className="slide active">
                                        {/* <img src={activeImage} className="image" /> */}
                                        <Canvas className='model' key={componetSelectedItem}>
                                            <ambientLight />
                                            {renderModelComponent()}
                                            <OrbitControls enableZoom={true} />
                                        </Canvas>
                                    </div>
                                </div>
                                <div className="right-arrow">
                                    {/* <img src="/static/media/right-arrow.8e05d407d31867d4acc0d73839982e33.svg" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="dual-option-buttons-container">
                            <button className="button">save suit</button>
                            <button className="button">select suit</button>
                        </div>
                    </div>
                    <div className="simulations-picker" />
                </div>
            </main>

            <Footer></Footer>
        </div>
    );
}

export default CreateSuit;