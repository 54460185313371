import React from 'react';
import 'assets/css/suitlibrary.scss'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import FrontNavbar from 'front/FrontNavbar';
import Header from 'front/Header/Header';
import { useState } from 'react';
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
} from "reactstrap";
import Footer from 'front/Footer/Footer';
function SuitLibrary(props) {
  const location = useLocation();
  const [modalActive, setModalActive] = useState(false);
  const[activeSelectedModel,setSelectedModel] = useState("");
  const [activeButton, setActiveButton] = useState('preBuiltSuit');

  const handleClick = (button) => {
    setActiveButton(button);
  };
  

  const handleModal = (model) => {
    setSelectedModel(model);
    setModalActive(!modalActive);
  };
  return (
    <div>
      {/* <FrontNavbar currentRoute={location.pathname}></FrontNavbar> */}
      <Header currentRoute={location.pathname}></Header>
      <main className="content" style={{ background: '#282c34', height: '100vh' }}>
        <div className="outer-container">
          <div className="switch-container text-white">
            <div className="tab-switch-wrapper">
              <button
                className={activeButton === 'mySuit' ? 'switch-label focused-switch' : 'switch-label'}
                onClick={() => handleClick('mySuit')}
              >
                my suits
              </button>
              <button
                className={activeButton === 'preBuiltSuit' ? 'switch-label focused-switch' : 'switch-label'}
                onClick={() => handleClick('preBuiltSuit')}
              >
                pre built suits
              </button>
            </div>
          </div>
          <div className="suits-preview-container scrollbar">
            <button id="Terra" className="suits-container">
              <label className="suit-label">Terra</label>
              <img src="https://bioastroause.blob.core.windows.net/suit/terra/terra_(2x3).png" className="suit-img " />
              <a className="switch-label select-button" onClick={() => {handleModal('Terra')}}>select suit</a>
            </button>
            <button id="Aqua" className="suits-container ">
              <label className="suit-label">Aqua</label>
              <img src="https://bioastroause.blob.core.windows.net/suit/aqua/aqua_(2x3).png" className="suit-img " />
              <a className="switch-label select-button" onClick={() => {handleModal('Aqua')}}>select suit</a>
            </button>
            <button id="Vyom" className="suits-container">
              <label className="suit-label">Vyom</label>
              <img src="https://bioastroause.blob.core.windows.net/suit/vyom/vyom_(2x3).png" className="suit-img " />
              <a className="switch-label select-button" onClick={() => {handleModal('Vyom')}}>select suit</a>
            </button>
          </div>
          <div className="simulations-picker" />
        </div>
      </main>

      <div className={`modal ${(modalActive) ? 'active' : ''}`}>
        <h3 className="modal-header" />
        <div className="modal-main border">
          <div className="modal-container">
            <label>You have chosen the {activeSelectedModel} model. Do you wish to continue or change the suit model</label>
            {/* <label>Changing your session settings will end your session and take you to the session set up screen.</label> */}
            {/* <label className="info-bold">Do you wish to end you session?</label> */}
            <Button onClick={() => {handleModal("")}}>
              {/* END SESSION */}
              CHOOSE ANOTHER SUIT
            </Button>
            <Button className="continue-button">CONTINUE SESSION</Button>
          </div>
        </div>
      </div>
     <Footer></Footer>
    </div>
  );
}

export default SuitLibrary;