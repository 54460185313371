import { info } from 'common/logger';
import { useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'; // Import QueryClient and QueryClientProvider
import { DataLoader } from 'components';
import { ACTIVE_SESSION_KEY } from './common/constants';
import './SimulationPage.scss';
import 'assets/css/App.css';
import { TaskPanel, ScenePreview } from './components';
import { SimulationProvider, useSimulation } from './SimulationContext';
import Header from 'front/Header/Header';
import Footer from 'front/Footer/Footer';
const ComponentViewer = ({ setIsSessionStarted }) => {
  return (
    <div className="simulation-page-container">
      <TaskPanel />
      <ScenePreview onTaskSelected={(val) => setIsSessionStarted(val)} />
    </div>
  );
};

const SimulationState = () => {
  const location = useLocation();
  const queryClient = new QueryClient(); // Create a new instance of QueryClient
  info('Loading simulation');
  const activeSession = JSON.parse(localStorage.getItem(ACTIVE_SESSION_KEY));
  console.log(activeSession);
  return (
    <div className='app' style={{height:'110vh'}}>
    <QueryClientProvider client={queryClient}> 
    <Header  currentRoute={location.pathname}></Header>
      <SimulationProvider simulationId={activeSession?.type.toLowerCase()}>
        <DataLoader query={useSimulation} page={'Simulation'}>
          <ComponentViewer />
        </DataLoader>
      </SimulationProvider>
    </QueryClientProvider>
    <div>
    <Footer></Footer>
    </div>
    </div>

  );
};

export default SimulationState;
