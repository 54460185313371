import './SingleNumberTile.scss'

const SingleNumberTile = ({ metric }) => {
  return (
    <div className={`single-panel ${metric.isAlert ? 'alert' : ''} ${metric?.typeTag ? 'emotibit-card' : ''}`}>
      <label className={`value ${metric?.typeTag ? 'emotibit-card' : ''}`}>{metric.value}</label>
      <label className={`unit ${metric?.typeTag ? 'emotibit-card' : ''}`}>{metric.unit}</label>
    </div>
  )
}
export default SingleNumberTile
