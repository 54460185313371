import { Accordion, MenuList } from 'components'
import './MetricListPanel.scss'

const RenderChild = ({ item, inx, icon, metricStack, selectedChild, setMetricStack, setMetricTiles, metricTiles }) => {
  const onMetricToggled = (metricName) => {
    const updatedMetricStack = [...metricStack]
    const updatedMetricTitles = [...metricTiles]
    if (metricStack.findIndex((metric) => metric.id === metricName.id) === -1) {
      if (metricStack.length >= 6) {
        updatedMetricTitles[5] = metricName
        updatedMetricStack[5] = metricName
        setMetricStack(updatedMetricStack)
      } else {
        const findNumberIndex = metricTiles.findIndex((metric) => !isNaN(metric))
        updatedMetricTitles[findNumberIndex] = metricName
        setMetricStack((currentStack) => [...currentStack, metricName])
      }
      setMetricTiles(updatedMetricTitles)
    } else {
      const tileIndex = metricTiles.findIndex((metric) => metric?.id === metricName.id)
      const filteredStack = updatedMetricStack.filter((metric) => metric.id !== metricName.id)
      updatedMetricTitles[tileIndex] = tileIndex
      setMetricTiles(updatedMetricTitles)
      setMetricStack(filteredStack)
    }
  }

  if (item.children) {
    return (
      <Accordion
        key={item.id}
        title={item.name}
        showIndicator={selectedChild.findIndex((x) => x?.id === item?.id) !== -1}
        itemClassName="custom-accordion-item"
        sectionClassName="custom-accordion-section">
        <MenuList key={inx} data={item.children} icon={icon} onSelected={onMetricToggled} activeItem={metricStack} />
      </Accordion>
    )
  } else {
    return <MenuList key={inx} data={item} icon={icon} onSelected={onMetricToggled} activeItem={metricStack} />
  }
}

export default function MetricListPanel({
  accordionData,
  getSelectedChild,
  metricStack,
  setMetricStack,
  setMetricTiles,
  metricTiles,
}) {
  return (
    <div className="metric-list-panel scrollbar">
      {accordionData.map(({ name, id, content, icon }) => {
        const selectedChild = getSelectedChild(content)
        return (
          <Accordion key={id} title={name} icon={icon} showIndicator={selectedChild.length > 0}>
            {content.map((item, inx) => {
              return (
                <RenderChild
                  selectedChild={selectedChild}
                  icon={icon}
                  metricStack={metricStack}
                  setMetricStack={setMetricStack}
                  setMetricTiles={setMetricTiles}
                  metricTiles={metricTiles}
                  key={inx}
                  item={item}
                  inx={inx}
                />
              )
            })}
          </Accordion>
        )
      })}
    </div>
  )
}
